// 车辆清运历史列表表头
export const historyColumns = () => {
  return [
    {
      title: '#',
      width: 70,
      align: 'center',
      scopedSlots: { customRender: '_index' }
    },
    {
      title: '执行司机',
      width: 100,
      align: 'center',
      dataIndex: 'driver.name'
    },
    {
      title: '执行时间',
      width: 360,
      align: 'center',
      scopedSlots: { customRender: 'executeTime' }
    },
    {
      title: '执行车辆',
      width: 100,
      align: 'center',
      dataIndex: 'carInfo.platNumber'
    },
    {
      title: '所属部门',
      width: 100,
      align: 'center',
      dataIndex: 'department.name'
    },
    {
      title: '行车公里',
      width: 100,
      align: 'center',
      dataIndex: 'mileage'
    },
    {
      title: '清运时长',
      width: 100,
      align: 'center',
      dataIndex: 'durationStr'
    },
    {
      title: '执行状态',
      width: 100,
      align: 'center',
      scopedSlots: { customRender: 'status' }
    },
    {
      title: '清运概述',
      width: 100,
      align: 'center',
      dataIndex: 'summary'
    }
  ]
}
