<template>
  <div style="width: 100%; height: 100%;">
    <div class="map-composite" :id="detailMapContainerId" style="width: 100%; height: 100%;min-height: 700px">
    </div>
    <div
      style="position:absolute;top: 90px;margin-left:40px;z-index: 100;">
      <a-form layout="inline">
        <a-form-item>
          <a-select style="width: 200px"
                    @change="selectCarInfo"
                    v-model="carId"
                    placeholder="请选择车辆">
            <a-select-option
              v-for="item in carList"
              :key="item.id"
              :value="item.id"
            >
              {{ item.platNumber }}({{item.status==='FREE'?'空闲中':item.status==='GOING'?'执行中':'离线'}})
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="">
          <a-radio-group @change="selectDateOption" button-style="solid" v-model="date">
            <a-radio-button value="today">今天</a-radio-button>
            <a-radio-button value=yesterday>昨天</a-radio-button>
            <a-radio-button value=week>本周</a-radio-button>
            <a-radio-button value=preWeek>上周</a-radio-button>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="">
          <a-range-picker @change="dateChange" v-model="rangeTime" show-time>
            <a-icon slot="suffixIcon" type="calendar"/>
          </a-range-picker>
        </a-form-item>
        <a-form-item class="f-r m-r-none">
          <a-button type="primary" @click="search" :loading="loading" icon="search">轨迹</a-button>
        </a-form-item>
      </a-form>
    </div>
    <div style="position:absolute;bottom: 40px;margin-left:40px;z-index: 100;">
      <span
        style="font-size: xx-large;font-weight: bolder;color: #2b97ff;">行驶总里程：&nbsp;&nbsp;{{mileage}}&nbsp;&nbsp;KM</span>
    </div>
  </div>
</template>

<script>
  import SERVICE_URLS from '@/api/service.url'
  import UUID from '../../../utils/UUID'
  import { lazyAMapApiLoaderInstance } from 'vue-amap'
  import { mapState } from 'vuex'
  import mapInit from './../../map/mixins/map-init'
  import mapStyle from './../../map/composite/mixins/map-style'
  import moment from 'moment'

  export default {
    props: {
      carId: {
        type: String,
      },
      date: {
        type: String,
      }
    },
    name: 'HistoryTrack',
    mixins: [mapInit, mapStyle],
    data () {
      return {
        searchParams: {
          startTime: '',
          endTime: '',
          carId: null,
        },
        mileage: 0,
        carList: [],
        rangeTime: [],
        loading: false,
        /*地图*/
        detailMapContainerId: new UUID().id,
        detailMap: null,
        marker: null,
        pathArray: null
      }
    },
    computed: {
      ...mapState({
        bodyHeight: (state) => {
          return state.app.bodyHeight - 200
        }
      })
    },
    mounted () {
      this.searchParams.startTime = moment().startOf('day').format('YYYY-MM-DD HH:mm:ss')
      this.searchParams.endTime = moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')
      this.rangeTime = [this.searchParams.startTime, this.searchParams.endTime]
      this.loadCarInfoList()
      this.searchParams.carId = this.carId
      if (this.date === "today") {
        this.searchParams.startTime = moment().day(moment().day()).startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.searchParams.endTime = moment().format('YYYY-MM-DD HH:mm:ss')
        if (this.carId != null) {
          this.search()
        }
      }
    },
    methods: {
      init () {
        lazyAMapApiLoaderInstance.load().then(() => {
          this.detailMap = new AMap.Map(this.detailMapContainerId, {
            resizeEnable: true,
            mapStyle: 'amap://styles/normal',
            center: [106.230909, 38.487193],
            zoom: 11
          })
          this.buildMapOtherLayer()
        })
      },
      selectCarInfo (id) {
        this.searchParams.carId = id
      },
      selectDateOption (e) {
        var type = e.target.value
        if (type === 'today') {
          this.searchParams.startTime = moment().day(moment().day()).startOf('day').format('YYYY-MM-DD HH:mm:ss')
          this.searchParams.endTime = moment().format('YYYY-MM-DD HH:mm:ss')
        } else if (type === 'yesterday') {
          this.searchParams.startTime = moment().day(moment().day() - 1).startOf('day').format('YYYY-MM-DD HH:mm:ss')
          this.searchParams.endTime = moment().day(moment().day() - 1).endOf('day').format('YYYY-MM-DD HH:mm:ss')
        } else if (type === 'week') {
          this.searchParams.startTime = moment().week(moment().week()).startOf('week').format('YYYY-MM-DD HH:mm:ss')
          this.searchParams.endTime = moment().format('YYYY-MM-DD HH:mm:ss')
        } else if (type === 'preWeek') {
          this.searchParams.startTime = moment().week(moment().week() - 1).startOf('week').format('YYYY-MM-DD HH:mm:ss')
          this.searchParams.endTime = moment().week(moment().week() - 1).endOf('week').format('YYYY-MM-DD HH:mm:ss')
        }
        this.rangeTime = [this.searchParams.startTime, this.searchParams.endTime]
      },
      dateChange (date, dateString) {
        this.searchParams.startTime = dateString[0]
        this.searchParams.endTime = dateString[1]
      },
      loadCarInfoList () {
        this.$http(this, {
          url: SERVICE_URLS.car.carInfoApi.select,
          noTips: true,
          success: (data) => {
            this.carList = data.body
          }
        })
      },
      search () {
        const startTimestamp = new Date(this.searchParams.startTime).getTime()
        const endTimestamp = new Date(this.searchParams.endTime).getTime()
        if ((endTimestamp - startTimestamp) > 7 * 24 * 60 * 60 * 1000) {
          this.$message.warning('查询范围不能超过一周！')
          return
        }
        if (this.searchParams.carId > 0) {
          this.$http(this, {
            url: SERVICE_URLS.car.carInfoApi.time_gis_trail,
            noTips: true,
            loading: 'loading',
            data: this.searchParams,
            success: (data) => {
              if (data.body.mileage > 0) {
                this.mileage = data.body.mileage
              }
              if (data.messageCode === 'fetch.success') {
                this.drawLine(data.body.carInfo.id, data.body.trail)
              } else {
                this.$message.warning('未能从平台获取到目标轨迹！')
              }

            },
          })
        } else {
          this.$message.warning('请选择车辆！')
        }

      },
      /*画线方法*/
      drawLine (id, path) {
        this.detailMap.clearMap()
        var polyline = new AMap.Polyline({
          path: eval(path),
          showDir: true,
          strokeColor: '#2d8cf0', //线颜色--蓝色
          //strokeOpacity: this.online ? 0 : 1,     //线透明度
          strokeOpacity: 1, //线透明度
          strokeWeight: 4, //线宽
          lineJoin: 'round', // 折线拐点连接处样式
          extData: {
            key: id
          }
        })
        this.detailMap.add(polyline)
        this.drowPoint(path)
      },
      drowPoint (path) {
        var gisArray = eval(path)
        var startPoint = eval(gisArray[0])
        var endPoint = eval(gisArray[gisArray.length - 1])
        let startIcon = new AMap.Icon({
          // 图标尺寸
          size: new AMap.Size(25, 34),
          // 图标的取图地址
          image: '//a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png',
          // 图标所用图片大小
          imageSize: new AMap.Size(135, 40),
          // 图标取图偏移量
          imageOffset: new AMap.Pixel(-9, -3)
        })
        let startMarker = new AMap.Marker({
          map: this.detailMap,
          icon: startIcon,
          offset: new AMap.Pixel(-11, -30),
          position: [startPoint[0], startPoint[1]]
        })
        let endIcon = new AMap.Icon({
          size: new AMap.Size(25, 34),
          image: '//a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png',
          imageSize: new AMap.Size(135, 40),
          imageOffset: new AMap.Pixel(-95, -3)
        })
        let endMarker = new AMap.Marker({
          map: this.detailMap,
          icon: endIcon,
          offset: new AMap.Pixel(-13, -33),
          position: [endPoint[0], endPoint[1]]
        })
        /*回放标记*/
        this.pathArray = path
        this.marker = new AMap.Marker({
          map: this.detailMap,
          position: [startPoint[0], startPoint[1]],
          icon: 'https://webapi.amap.com/images/car.png',
          offset: new AMap.Pixel(-26, -13),
          autoRotation: true,
          angle: -90
        })
        var passedPolyline = new AMap.Polyline({
          map: this.detailMap,
          strokeColor: '#AF5', //线颜色
          strokeWeight: 6, //线宽
          lineJoin: 'round' // 折线拐点连接处样式
        })
        this.marker.on('moving', (e) => {
          passedPolyline.setPath(e.passedPath)
        })
        /*添加到地图*/
        this.detailMap.add(startMarker)
        this.detailMap.add(endMarker)
        this.detailMap.setFitView()
        this.handleRunTrail()
      },
      handleRunTrail () {
        this.marker.moveAlong(eval(this.pathArray), 5000)
      },
    }
  }
</script>

<style scoped>

</style>