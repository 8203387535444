<template>
  <a-row :gutter="16">
    <a-col :span="24" class="col-margin-padding">
      <div class="details" style="margin-top: 0px;">
        <div class="details-title" style="margin-top: 0;">
          <a-row>
            <a-col :span="14">
              <div class="details-title-text">
                {{selectTimeYearValue.format('YYYY') + '年' + '各月拉运公里时长统计'}}
              </div>
              <i class="details-title-text-i"></i>
            </a-col>
            <a-col :span="10">
              <YearPicker @selectYear="selectTimeYear" :defaultValue="selectTimeYearValue"></YearPicker>
              <a-select placeholder="请选择类型" v-model="searchYearParams.statistisType" style="width: 160px;margin-left: 10px" @change="onChangeYearType">
                <a-select-option v-for="item in typeOptions" :key="item.id" :value="item.id">
                  {{item.name}}
                </a-select-option>
              </a-select>
              <a-select
                style="width: 160px;margin-left: 10px"
                v-if="searchYearParams.statistisType === 1"
                allowClear
                showSearch
                optionFilterProp="label"
                v-model="searchYearParams.carInforIdOrUserId"
                placeholder="请选择车辆"
                @change="searchYear"
              >
                <a-select-option v-for="item in carList" :key="item.id" :value="item.id" :label="item.name">
                  {{ item.name }}
                </a-select-option>
              </a-select>
              <PersoinnelSelect v-else @onChange="selectUser"></PersoinnelSelect>
            </a-col>
          </a-row>
        </div>
      </div>
      <a-spin :spinning="spinning">
        <a-row class="chart-content">
          <a-col :span="24">
            <BarAndLine :data="everyYearData" id="barAndLine1"></BarAndLine>
          </a-col>
        </a-row>
      </a-spin>
    </a-col>
    <a-col :span="24">
      <div class="details" style="margin-top: 0px;">
        <div class="details-title" style="margin-top: 0;">
          <a-row>
            <a-col :span="14">
              <div class="details-title-text">{{selectEveryMonthYearValue.format('YYYY') + '年拉运公里时长统计'}}</div>
              <i class="details-title-text-i"></i>
            </a-col>
            <a-col :span="10">
              <YearPicker @selectYear="selectEveryMonthYear" :defaultValue="selectEveryMonthYearValue"></YearPicker>
              <MonthSelect placeholder="请选择月份" @onChange="onChangeMonth" style="margin-left: 10px"/>
              <a-select placeholder="请选择类型" v-model="searchMonthParams.statistisType" style="width: 160px;margin-left: 10px" @change="searchMonth">
                <a-select-option v-for="item in typeOptions" :key="item.id" :value="item.id">
                  {{item.name}}
                </a-select-option>
              </a-select>
            </a-col>
          </a-row>
        </div>
      </div>
      <a-spin :spinning="spinning">
        <a-row class="chart-content">
          <a-col :span="24">
            <BarAndLine :data="everyMonthData" xName="context" id="barAndLine2"></BarAndLine>
          </a-col>
        </a-row>
      </a-spin>
    </a-col>
  </a-row>
</template>
<script>
  import moment from 'moment'
  import YearPicker from '../module/YearPicker'
  import BarAndLine from '../../statisticsChart/carBarAndLine'
  import SERVICE_URLS from '@/api/service.url'
  import PersoinnelSelect from '../module/PersonnelSelect'
  import MonthSelect from '../module/MonthSelect'

  export default {
    components: { YearPicker, BarAndLine, PersoinnelSelect, MonthSelect },
    props: {
      mapId: {}
    },
    data () {
      return {
        spinning: false,
        selectTimeYearValue: moment().endOf('year'),
        selectEveryMonthYearValue: moment().endOf('year'),
        selectMonth: undefined,
        everyYearData: [],
        everyMonthData: [],
        selectOneCarName: '',
        searchYearParams: {
          carInforIdOrUserId: undefined,
          groupType: 1,
          mapId: this.mapId,
          statistisContentType: '2,3',
          statistisType: 1,
          timeStr: ''
        },
        searchMonthParams: {
          carInforIdOrUserId: undefined,
          groupType: 2, //按分组类型 1 月份 2 按分组类型
          mapId: this.mapId,
          statistisContentType: '2,3',
          statistisType: 2, // 类型 1车辆 2司机
          timeStr: ''
        },
        carList: [],
        typeOptions: [
          { id: 1, name: '车辆' },
          { id: 2, name: '司机' }
        ]
      }
    },
    watch: {
      mapId (val) {
        this.macId = val
      }
    },
    created () {
      this.loadCarInfoList()
      this.loadYearParams()
      this.loadMonthParams()
      this.searchYear()
      this.searchMonth()
    },
    methods: {
      moment,
      loadCarInfoList () {
        this.$http(this, {
          url: SERVICE_URLS.car.carInfoApi.select,
          noTips: true,
          success: (data) => {
            this.carList = data.body
          }
        })
      },
      loadYearParams () {
        this.searchYearParams = {
          carInforIdOrUserId: '',
          groupType: 1,
          mapId: this.mapId,
          statistisContentType: '2,3',
          statistisType: 1,
          timeStr: this.selectTimeYearValue.format('YYYY')
        }
      },
      selectTimeYear (val) {
        this.selectTimeYearValue = val
        this.loadYearParams()
        this.searchYear()
      },
      searchYear () {
        this.$http(this, {
          url: SERVICE_URLS.car.carStatisticsApi.statistics,
          noTips: true,
          data: this.searchYearParams,
          success: (data) => {
            this.everyYearData = data.body.data
          }
        })
      },
      onChangeYearType (value) {
        this.searchYearParams.carInforIdOrUserId = undefined
        this.searchYearParams.statistisType = value
        this.searchYear()
      },
      selectUser (id) {
        this.searchYearParams.carInforIdOrUserId = id
        this.searchYear()
      },
      // 月份查询
      loadMonthParams () {
        this.searchMonthParams = {
          carInforIdOrUserId: '',
          groupType: 2, //按分组类型 1 月份 2 按分组类型
          mapId: this.mapId,
          statistisContentType: '2,3',
          statistisType: 2, // 类型 1车辆 2司机
          timeStr: this.selectEveryMonthYearValue.format('YYYY')
        }
      },
      searchMonth () {
        console.log(this.searchMonthParams)
        this.$http(this, {
          url: SERVICE_URLS.car.carStatisticsApi.statistics,
          noTips: true,
          data: this.searchMonthParams,
          success: (data) => {
            this.everyMonthData = data.body.data
          }
        })
      },
      selectEveryMonthYear (val) {
        this.selectEveryMonthYearValue = val
        this.loadMonthParams()
        this.searchMonth()
      },
      onChangeMonth (e) {
        if (e < 10) {
         var month = '0' + e.toString()
        }
        this.searchMonthParams.timeStr = this.selectEveryMonthYearValue.format('YYYY') + month
        console.log(this.searchMonthParams.timeStr)
        this.searchMonth()
      }

    }
  }
</script>