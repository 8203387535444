<template>
  <a-row :gutter="16">
    <a-col :span="24" class="col-margin-padding">
      <div class="details" style="margin-top: 0px;">
        <div class="details-title" style="margin-top: 0;">
          <a-row>
            <a-col :span="17">
              <div class="details-title-text">{{selectOnMonthYearValue.format('YYYY') + '年' + onMonthName + '巡检次数统计'}}</div>
              <i class="details-title-text-i"></i>
            </a-col>
            <a-col :span="7" style="text-align: right;">
              <YearPicker @selectYear="selectOnMonthYear" :defaultValue="selectOnMonthYearValue"></YearPicker>
              <PersonnelSelect ref="personnelRef" @onChange="selectUser"></PersonnelSelect>
            </a-col>
          </a-row>
        </div>
      </div>
      <a-table :columns="monthColumns"
               :rowKey="(record,index) => index"
               bordered
               :pagination="false"
               :loading="loading1"
               :data-source="onMonthData">
      </a-table>
    </a-col>
    <a-col :span="24">
      <div class="details" style="margin-top: 0px;">
        <div class="details-title" style="margin-top: 0;">
          <a-row>
            <a-col :span="17">
              <div class="details-title-text">{{selectOnUserYearValue.format('YYYY') + '年' }}{{ selectMonth?selectMonth+'月':'' }}各人巡检次数公里统计</div>
              <i class="details-title-text-i"></i>
            </a-col>
            <a-col :span="7" style="text-align: right;">
              <YearPicker @selectYear="selectOnUserYear" :defaultValue="selectOnUserYearValue"></YearPicker>
              <MonthSelect ref="monthRef" @onChange="onChangeMonth" style="margin-left: 10px"></MonthSelect>
            </a-col>
          </a-row>
        </div>
      </div>
      <a-table :columns="userColumns"
               :rowKey="(record,index) => index"
               bordered
               :pagination="false"
               :loading="loading2"
               :data-source="onUserData">
      </a-table>
    </a-col>
  </a-row>
</template>
<script>
  import moment from 'moment'
  import YearPicker from '../module/YearPicker'
  import PersonnelSelect from '../module/PersonnelSelect'
  import MonthSelect from '../module/MonthSelect'
  import SERVICE_URLS from '@/api/service.url'

  export default {
    components: { YearPicker, PersonnelSelect, MonthSelect },
    props: {
      mapId: {}
    },
    watch: {
      mapId (val) {
        this.mapId = val
      }
    },
    data () {
      return {
        spinning: false,
        inspectionTimesTab: '',
        mode: 'year',
        time: '',
        selectOnMonthYearValue: moment().endOf('year'),
        selectOnUserYearValue: moment().endOf('year'),
        onMonthName: '',
        selectMonth: '',
        onMonthData: [],
        monthColumns: [
          {
            title: '月份',
            dataIndex: 'month',
            align: 'center',
            width: 100
          },
          {
            title: '巡检情况',
            children: [
              {
                title: '巡检次数',
                dataIndex: 'xjCount',
                align: 'center'
              },
              {
                title: '巡检公里(km)',
                dataIndex: 'kilometers',
                align: 'center'
              },
              {
                title: '巡检时长(h)',
                dataIndex: 'duration',
                align: 'center'
              }
            ]
          },
          {
            title: '巡检点情况',
            children: [
              {
                title: '巡检点巡检次数',
                dataIndex: 'xjNodeCount',
                align: 'center'
              },
              {
                title: '正常次数',
                dataIndex: 'normalCount',
                align: 'center'
              },
              {
                title: '异常次数',
                dataIndex: 'abnormalCount',
                align: 'center'
              }
            ]
          },
          {
            title: '工单处理情况',
            children: [
              {
                title: '待处理',
                dataIndex: 'unProcessCount',
                align: 'center'
              },
              {
                title: '处理中',
                dataIndex: 'processedCount',
                align: 'center'
              },
              {
                title: '已完成',
                dataIndex: 'finishedCount',
                align: 'center'
              }
            ]
          }
        ],
        userColumns: [
          {
            title: '姓名',
            dataIndex: 'nickname',
            align: 'center',
            width: 100
          },
          {
            title: '巡检情况',
            children: [
              {
                title: '巡检次数',
                dataIndex: 'xjCount',
                align: 'center'
              },
              {
                title: '巡检公里(km)',
                dataIndex: 'kilometers',
                align: 'center'
              },
              {
                title: '巡检时长(h)',
                dataIndex: 'duration',
                align: 'center'
              }
            ]
          },
          {
            title: '巡检点情况',
            children: [
              {
                title: '巡检点巡检次数',
                dataIndex: 'xjNodeCount',
                align: 'center'
              },
              {
                title: '正常次数',
                dataIndex: 'normalCount',
                align: 'center'
              },
              {
                title: '异常次数',
                dataIndex: 'abnormalCount',
                align: 'center'
              }
            ]
          },
          {
            title: '工单处理情况',
            children: [
              {
                title: '待处理',
                dataIndex: 'unProcessCount',
                align: 'center'
              },
              {
                title: '处理中',
                dataIndex: 'processedCount',
                align: 'center'
              },
              {
                title: '已完成',
                dataIndex: 'finishedCount',
                align: 'center'
              }
            ]
          }
        ],
        onUserData: [],
        searchOnMonthParams: {
          mapId: this.mapId,
          startTimeStr: '',
          endTimeStr: '',
          xjMonth: '',
          countType: 0,
          userId: ''
        },
        loading1: false,
        searchOnUserParams: {
          mapId: this.mapId,
          startTimeStr: '',
          endTimeStr: '',
          xjMonth: '',
          countType: 1,
          userId: ''
        },
        loading2: false

      }
    },
    created () {
      this.loadSearchOnMonthParams()
      this.searchOnMonth()
      this.loadSearchOnUserParams()
      this.searchOnUser()
    },
    methods: {
      moment,
      loadSearchOnMonthParams () {
        this.$nextTick(() => {
          this.$refs.personnelRef.userStr = undefined
        })
        this.searchOnMonthParams = {
          mapId: this.mapId,
          startTimeStr: this.getStartAndEndTimes(this.selectOnMonthYearValue, 'year')[0],
          endTimeStr: this.getStartAndEndTimes(this.selectOnMonthYearValue, 'year')[1],
          xjMonth: '',
          countType: 0,
          userId: ''
        }
      },
      // 获取开始时间结束时间
      getStartAndEndTimes (date, type) {
        const formatDate = new Date(date)
        const startTime = moment(formatDate).startOf(type).format('YYYY-MM-DD')
        const endTime = moment(formatDate).endOf(type).format('YYYY-MM-DD')
        return [startTime, endTime]
      },
      searchOnMonth () {
        this.loading1 = true
        this.$http(this, {
          url: SERVICE_URLS.xj.xjStatistics.count_inspect_data_with_month,
          noTips: true,
          data: this.searchOnMonthParams,
          success: (data) => {
            this.onMonthData = data.body.data
            this.loading1 = false
          }
        })
      },
      selectOnMonthYear (val) {
        this.selectOnMonthYearValue = val
        this.loadSearchOnMonthParams()
        this.searchOnMonth()
      },
      selectUser (userId) {
        this.searchOnMonthParams.userId = userId
        this.searchOnMonth()
      },
      // ========================= 按人统计
      loadSearchOnUserParams () {
        this.$nextTick(() => {
          this.$refs.monthRef.value = undefined
        })
        this.searchOnUserParams = {
          mapId: this.mapId,
          startTimeStr: this.getStartAndEndTimes(this.selectOnMonthYearValue, 'year')[0],
          endTimeStr: this.getStartAndEndTimes(this.selectOnMonthYearValue, 'year')[1],
          xjMonth: '',
          countType: 1,
          userId: ''
        }
      },
      searchOnUser () {
        this.loading2 = true
        this.$http(this, {
          url: SERVICE_URLS.xj.xjStatistics.count_inspect_data_with_user,
          noTips: true,
          data: this.searchOnUserParams,
          success: (data) => {
            this.onUserData = data.body.data
            this.loading2 = false
          }
        })
      },
      selectOnUserYear (val) {
        this.selectOnUserYearValue = val
        this.loadSearchOnUserParams()
        this.searchOnUser()
      },
      // 当选择月份处理为202006类型字符串
      isSelectMonth (e) {
        if (e < 10) {
          var month = '0' + e.toString()
        }
        const date = this.selectOnUserYearValue.format('YYYY') + '-' + month
        return date
      },
      onChangeMonth (value) {
        this.selectMonth = value
        if (value) {
          this.searchOnUserParams.xjMonth = this.isSelectMonth(value)
        } else {
          this.searchOnUserParams.xjMonth = ''
        }
        this.searchOnUser()
      }
    }
  }
</script>
<style lang="less">
</style>