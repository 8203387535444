<template>
  <div :id="id"></div>
</template>
<script>
  import G2 from '@antv/g2'

  export default {
    name: 'bar',
    props: {
      data: Array,
      id: {
        type: String
      },
      xName: {
        type: String,
        default: 'item'
      }
    },
    data () {
      return {
        chart: null
      }
    },
    watch: {
      data (val) {
        this.data = val
        this.chart.source(this.data)
        this.chart.repaint()
      }
    },
    mounted () {
      this.initChart()
    },
    methods: {
      initChart () {
        const chart = new G2.Chart({
          container: this.id,
          forceFit: true,
          height: 300,
          padding: 'auto'
        })
        chart.source(this.data)
        chart.tooltip({
          showTitle: false,
          itemTpl: '<li><span style="background-color:{color};" class="g2-tooltip-marker"></span>{name}: {value}</li>'
        })
        chart.axis('kilometers', {
          label: {
            formatter: val => {
              return val + 'km'
            }
          }
        })
        chart.axis('duration', {
          label: {
            formatter: val => {
              return val + 'h'
            }
          }
        })
        chart.legend(false)
        chart.intervalStack()
          .position(`${this.xName}` + '*duration')
          .color(`${this.xName}`)
          .tooltip(`${this.xName}` + '*duration', (item, count) => {
            return {
              name: '小时',
              value: count + 'h'
            }
          })
        chart.line().position(`${this.xName}` + '*kilometers')
          .tooltip(`${this.xName}` + '*kilometers', (item, percent) => {
            return {
              name: '公里',
              value: percent.toFixed(2) + 'km'
            }
          })
        chart.point().position(`${this.xName}` + '*kilometers')
        this.chart = chart
        this.chart.render()
      }
    }
  }
</script>
<style>
</style>