<template>
  <a-spin :spinning="spinShow">
    <div class="form-search-body" style="padding: 10px;overflow: scroll;min-height: 290px;" :style="{'max-height': bodyHeight + 'px'}">
      <a-tree :key="key" v-if="treeShow === true && treeData.length>0" show-line :showIcon="true" :load-data="onLoadData" :treeData="treeData" :defaultExpandedKeys="[1]"
              @select="itemClick"
              :defaultSelectedKeys="['1-0']">
        <a-icon slot="switcherIcon" type="caret-down"/>
        <template slot="custom" slot-scope="item">
          <a-row>
            <span v-if="item.type !== 'OTHER'">
              <a-icon type="environment" v-if="item.plotType==='marker'" style="margin-right: 10px;"/>
            <a-icon type="dash" v-if="item.plotType==='polyline'" style="margin-right: 10px;"/>
            <a-icon type="radius-setting" v-if="item.plotType==='polygon'" style="margin-right: 10px;"/>
            </span>
            <span>{{item.title}}</span>
            <span style="color: #00A0E9">{{item.num ? '(' + item.num + ')' : ''}}</span>
          </a-row>
        </template>
      </a-tree>
      <a-tree v-if=" treeShow === false && searchTreeData.length" show-line :defaultExpandAll="true" :treeData="searchTreeData" @select="itemClick">
        <a-icon slot="switcherIcon" type="caret-down"/>
        <template slot="custom" slot-scope="item">
          <a-row>
            <span v-if="item.type !== 'OTHER'">
              <a-icon type="environment" v-if="item.plotType==='marker'" style="margin-right: 10px;"/>
            <a-icon type="dash" v-if="item.plotType==='polyline'" style="margin-right: 10px;"/>
            <a-icon type="radius-setting" v-if="item.plotType==='polygon'" style="margin-right: 10px;"/>
            </span>
            <span>{{item.title}}</span>
            <span style="color: #00A0E9">{{item.num ? '(' + item.num + ')' : ''}}</span>
          </a-row>
        </template>
      </a-tree>
      <a-empty v-if="treeData.length === 0 && treeShow === true" style="height: 205px;margin-top: 81px;"/>
      <a-empty v-if="searchTreeData.length === 0 && treeShow === false " style="height: 205px;margin-top: 81px;"/>
    </div>
  </a-spin>
</template>

<script>
  import SERVICE_URLS from '@/api/service.url'
  import searchListCommon from '../mixins/search-list-common'
  import { mapState } from 'vuex'

  export default {
    name: 'SearchDeviceList',
    mixins: [searchListCommon],
    props: {
      searchKey: {
        type: String,
        default: 'device'
      },
      mapId: {
        type: String,
        default: undefined
      }
    },
    data () {
      return {
        spinShow: false,
        searchParams: {
          page: 0,
          size: 10,
          // 设备，仓库，设施的名称
          name: null,
          mapInfoId: this.mapId,
          // 类型标签
          typeLabel: null
        },
        searchData: [],
        deviceTypeList: [],
        listType: 'monitoringPoint',
        id: '0',
        treeData: [],
        searchTreeParams: {
          treeNodeId: 0,
          needMapOverlay: true,
          needNum: true,
          levelNum: 2,
          mapId: this.mapId,
          needPlotNode: true
        },
        searchTreeData: [],
        treeShow: true,
        key: 1
      }
    },
    computed: {
      ...mapState({
        bodyHeight: state => {
          return state.app.bodyHeight - 200
        }
      })
    },
    created () {
      this.loadMonitoringPointTree()
    },
    methods: {
      /**
       * searchKey发生变化 watch会执行当前方法
       * @param value 变化值
       */
      searchKeyChange (value) {
        this.searchParams.name = value
      },
      /**
       * 查询
       */
      loadData () {
        if (this.spinShow) {
          return
        }
        this.$emit('onSearchStart')
        this.$http(this, {
          url: SERVICE_URLS.equipment2.monitorPointApi.search,
          noTips: true,
          loading: 'spinShow',
          data: this.searchParams,
          success: (data) => {
            this.searchData = data.body.content
            this.pagination.total = data.body.totalElements
            this.pagination.current = this.searchParams.page + 1
            this.$emit('onSearchSuccess')
          },
          error: () => {
            this.$emit('onSearchError')
            this.$message.error('设备列表检索失败！')
          }
        })
      },
      /**
       * 加载监测点类型列表
       */
      onSearch (value) {
        if (value) {
          this.treeShow = false
          this.$nextTick(() => {
            this.$http(this, {
              url: SERVICE_URLS.equipment2.monitoringPoint.search_tree_with_map_data_auth,
              noLoadingBar: true,
              noTips: true,
              loading: 'spinShow',
              data: {
                name: value,
                treeNodeId: 0,
                mapId: this.mapId,
                needPlotNode: true,
                needNum: true,
                needMapOverlay: true
              },
              success: (data) => {
                this.searchTreeData = data.body
              }
            })
          })
        } else {
          this.treeShow = true
          ++this.key
        }
      },
      loadMonitoringPointTree () {
        this.$http(this, {
          url: SERVICE_URLS.equipment2.monitoringPoint.build_tree_with_map_data_auth,
          noTips: true,
          loading: 'spinShow',
          data: this.searchTreeParams,
          success: (data) => {
            this.treeData = []
            this.treeData = data.body
          }
        })
      },
      onLoadData (treeNode) {
        this.searchTreeParams.treeNodeId = treeNode.$vnode.key
        this.searchTreeParams.levelNum = 1
        return new Promise(resolve => {
          this.$http(this, {
            url: SERVICE_URLS.equipment2.monitoringPoint.build_tree_with_map_data_auth,
            noLoadingBar: true,
            noTips: true,
            data: this.searchTreeParams,
            success: (data) => {
              treeNode.dataRef.children = data.body
              this.treeData = [...this.treeData]
            }
          })
          resolve()
        })
      },
      itemClick (item, e) {
        if (e.node.dataRef.type !== 'OTHER') {
          this.$emit('onItemClick', this.listType, e.node.dataRef)
        }
      }
    }
  }
</script>
