<template>
  <right-form-container :title="formItem.name" v-model="visible" @close="close">
    <div slot="title" v-if="select">
      <div>
        <a-dropdown style="float: left;">
          <div class="ant-modal-title">
            <template>
              &nbsp;<a>
              <span style="font-size: 14px; color: white; font-weight: normal;">{{ deviceSelectName }}</span></a
            >
            </template>
            &nbsp;<a-icon type="down" style="color: white;"/>
          </div>
          <a-menu slot="overlay" style="border-radius: 5px; width: 55%;">
            <a-menu-item v-for="item in deviceSelect" :key="item.id">
              <a target="_blank" @click="handleCheckInstanceType(item, 'selectClick')"
              >{{ item.name }} &nbsp;&nbsp;<a-icon
                v-if="item.name === deviceSelectName"
                type="check"
                style="color: #1e88e5; margin-top: 4px;"
                class="f-r"
              />
              </a>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </div>
    </div>
    <a-spin :spinning="spinShow" :delay="200">
      <div
        v-malihu-scrollbar
        class="mCustomScrollbar"
        style="overflow-y: auto; position: relative; top: 0; left: 0;"
        :style="{ 'max-height': bodyHeight + 'px' }"
      >
        <div class="p-12">
          <a-form layout="inline">
            <a-row>
              <a-form-item label="所属部门" class="form-line-height">
                <span>{{ formItem.deptName }}</span>
              </a-form-item>
            </a-row>
            <a-row>
              <a-form-item label="所在位置" class="form-line-height">
                <span>{{ formItem.location }}</span>
              </a-form-item>
            </a-row>
          </a-form>
        </div>
        <div class="banner-img">
          <span v-if="images.length">
            <template v-for="(item, index) in images">
              <button
                v-show="index === 0"
                class="left_btn preview-img image-top-btn"
                @click="$preview.open(index, images, config)"
                style="cursor: pointer;"
              >
                更多图片
              </button>
            </template>
          </span>
          <span v-else>
            <button
              class="left_btn preview-img image-top-btn"
              @click="$message.warning('暂无图片')"
              style="cursor: pointer;"
            >
              更多图片
            </button>
          </span>
          <button class="right_btn image-top-btn" @click="moreVideo" style="cursor: pointer;">视频监控</button>
          <img v-if="images.length > 0" :src="images[0].src + '?imageView2/1/w/450/h/100'"/>
        </div>
        <template>
          <a-dropdown style="text-align: center; color: #ffffff; background: red;">
            <div
              class="warn-info ant-dropdown-link"
              v-show="currentItem.label !== 'DEPOT' && warningInfoList.length"
              style="cursor: pointer;"
            >
              预警信息
            </div>
            <a-menu slot="overlay">
              <template v-for="item in warningInfoList">
                <a-menu-item>
                  <div
                    @click="clickWarningInfo(item.id)"
                    :style="{ color: item.warningType === 'FAULT' ? '#f5222d' : '#1890ff' }"
                  >
                    <a-icon
                      :type="item.warningType === 'FAULT' ? 'warning' : 'info-circle'"
                      style="margin-right: 10px;"
                    />
                    {{ item.title }}
                  </div>
                </a-menu-item>
                <a-menu-divider/>
              </template>

              <a-menu-item key="index" style="text-align: center; font-weight: bold;" v-if="warningInfoList.length > 0">
                <div @click="clickMoreWarningInfo()">查看更多</div>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </template>
        <div class="p-12">
          <a-row :gutter="16" v-if="formItem.metrics">
            <a-col class="metrics-col" :span="12" v-for="(item, index) in formItem.metrics" :key="index">
              <metrics :value="item.value" :unit="item.unit" :name="item.name"></metrics>
            </a-col>
            <a-col
              class="metrics-col"
              :span="12"
              v-if="formItem.metrics.length > 0 && currentItem.label !== 'DEPOT'"
              @click="moreMetrics"
              style="cursor: pointer;"
            >
              <metrics :value="moreMetricsString">
                <span slot="title"><a-icon type="bars"/></span>
              </metrics>
            </a-col>
            <a-col
              class="metrics-col"
              :span="12"
              v-if="formItem.deviceCount > 0 && currentItem.label === 'FACILITY'"
              @click="loadDeviceByFacilityId(currentItem.id)"
              style="cursor: pointer;"
            >
              <metrics :value="includeEquipment" :name="formItem.deviceCount"></metrics>
            </a-col>
          </a-row>
        </div>
        <a-divider style="margin: 10px 0 6px 0;"></a-divider>
        <div class="p-12 p-b-20">
          <a-row :gutter="16">
            <a-col :span="8">
              <a-button block type="primary" @click="clickGatewayInfo" v-if="currentItem.label !== 'DEPOT'"
              >网关信息
              </a-button
              >
            </a-col>
            <a-col :span="8">
              <a-button block type="primary" @click="clickEngineeringArchive">
                工程档案
              </a-button>
            </a-col>
            <a-col :span="8">
              <a-button block type="primary" @click="clickFlowChart" v-if="currentItem.label !== 'DEPOT'">组态图</a-button>
            </a-col>
          </a-row>
        </div>
      </div>
    </a-spin>
    <a-modal v-model="moreMetricsModal" width="850px" :footer="null">
      <more-metrics-detail ref="moreMetricsRef"></more-metrics-detail>
      <div slot="title" v-if="currentItem.label === 'DEVICE'">
        {{ formItem.name }}
      </div>
      <div slot="title" v-else>
        <a-dropdown>
          <div style="display: inline-block;">
            {{ tempDeviceInfo.name }}
            <a-icon type="down"/>
          </div>
          <a-menu slot="overlay">
            <a-menu-item v-for="(item, index) in deviceSelect" :key="index" :value="item.name">
              <a target="_blank" @click="selectDevice(item)"
              >{{ item.name }}
                <a-icon v-if="item.id === tempDeviceInfo.id" type="check" style="color: #1e88e5;"/>
              </a>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </div>
    </a-modal>
    <a-modal :title="formItem.name + '的网关信息'" :footer="null" :width="bodyWidth - 400" v-model="gatewayModal">
      <device-gateway-info ref="gatewayInfoRef"></device-gateway-info>
    </a-modal>
    <a-modal :title="formItem.name + '的工程档案'" :footer="null" :width="bodyWidth" v-model="engineeringArchiveModal">
      <device-engineering-archive ref="engineeringArchiveRef"></device-engineering-archive>
    </a-modal>
    <a-modal title="预警信息管理" :footer="null" :width="1450" v-model="warningModal">
      <WarningTable ref="warningTableRef"></WarningTable>
    </a-modal>
    <a-modal :title="formItem.name + '预警信息详情'" :footer="null" :width="1000" v-model="warningDetailModal">
      <WarningDetail ref="warningDetailRef"></WarningDetail>
    </a-modal>
    <a-modal
      title="摄像头预览"
      @cancel="detailClose"
      :maskClosable="false"
      width="1048px"
      :footer="null"
      :visible="detailDrawer"
    >
      <h-k-player ref="hkPlayerRef" :cameraIndexCodes="testCameraIndexCodes"></h-k-player>
    </a-modal>
    <a-modal
      title="摄像头预览"
      @cancel="detailClose"
      :maskClosable="false"
      width="1048px"
      :footer="null"
      :visible="detailDrawer"
    >
      <h-k-player ref="hkPlayerRef" :cameraIndexCodes="testCameraIndexCodes"></h-k-player>
    </a-modal>
    <a-modal
      title="工艺图"
      @cancel="closeChart"
      :maskClosable="false"
      width="1048px"
      :footer="null"
      :visible="chartModal"
    >
      <DeviceFlowChart ref="" :technologyId="technologyId"></DeviceFlowChart>
    </a-modal>
  </right-form-container>
</template>

<script>
  import moment from 'moment'
  import UUID from '../../../utils/UUID'
  import '../../../core/amap'
  import { mapState } from 'vuex'
  import SERVICE_URLS from '@/api/service.url'

  import containerClose from './mixins/container-close'
  import containerHeight from '../mixins/container-height'
  import RightFormContainer from './RightFormContainer'
  import { previewConfig } from '../../../utils/preview'
  import DeviceGatewayInfo from './DeviceGatewayInfo'
  import DeviceEngineeringArchive from './DeviceEngineeringArchive'
  import MoreMetricsDetail from '../../equipment2/monitoring/Detail'
  import Metrics from './Metrics'
  import WarningTable from '../../equipment2/warning/Table'
  import WarningDetail from '../../equipment2/warning/Detail'
  import HKPlayer from '../../hkcamera/HKPlayer'
  import DeviceFlowChart from './DeviceConfiguration'

  export default {
    name: 'DeviceDetail',
    components: {
      RightFormContainer,
      DeviceGatewayInfo,
      DeviceEngineeringArchive,
      MoreMetricsDetail,
      Metrics,
      WarningTable,
      WarningDetail,
      HKPlayer,
      DeviceFlowChart
    },
    mixins: [containerClose, containerHeight],
    props: {
      mapId: {
        type: String,
        default: undefined
      }
    },
    data () {
      return {
        config: previewConfig,
        spinShow: false,
        currentItem: {},
        formItem: {},
        formatStr: 'YYYY/MM/DD HH:mm:ss',
        mapContainerId: new UUID().id,
        map: undefined,
        images: [],
        getDeviceInfoAPI: SERVICE_URLS.equipment2.equipmentInfoApi.aggregationInfo,
        getDepotInfoAPI: SERVICE_URLS.equipment2.depotApi.aggregationInfo,
        getFacilityInfoAPI: SERVICE_URLS.equipment2.facilityApi.aggregationInfo,
        select: false,
        deviceSelect: [],
        deviceSelectName: '',
        gatewayModal: false,
        engineeringArchiveModal: false,
        moreMetricsModal: false,
        tempDeviceInfo: {
          id: '',
          name: ''
        },
        moreMetricsString: '更多指标',
        includeEquipment: '包含设备',
        warningParams: {
          facilityId: '',
          deviceId: '',
          type: '',
          mapId: this.mapId,
          warningDealStatus: 'UN_PROCESSED'
        },
        warningInfoList: [],
        warningModal: false,
        warningDetailModal: false,
        detailDrawer: false,
        testCameraIndexCodes: [],
        technologyId: '2',
        chartModal: false
      }
    },
    computed: {
      ...mapState({
        bodyWidth: (state) => {
          const width = state.app.bodyWidth - 140
          return width > 1400 ? 1400 : width
        }
      })
    },
    methods: {
      moment,
      /**
       * @param item 左侧点击item
       */
      loadData (item) {
        this.setCurrentItem(item)
        const clickType = 'leftListClick'
        if (item.label === 'DEVICE') {
          const params = {
            deviceId: item.id
          }
          this.getClickItemInfo(params, this.getDeviceInfoAPI, clickType)
        } else if (item.label === 'DEPOT') {
          const params = {
            depotId: item.id
          }
          this.getClickItemInfo(params, this.getDepotInfoAPI, clickType)
        } else {
          const params = {
            facilityId: item.id
          }
          this.getClickItemInfo(params, this.getFacilityInfoAPI, clickType)
        }
        if (this.currentItem.label !== 'DEPOT') {
          this.getWarningInfo()
        }
      },
      getClickItemInfo (params, api, clickType) {
        if (clickType === 'selectClick') {
          this.select = true
        } else {
          this.select = false
        }
        this.$http(this, {
          url: api,
          noTips: true,
          loading: 'spinShow',
          params: params,
          success: (data) => {
            this.formItem = data.body
            if (this.formItem.sceneImages) {
              this.imageForEach(this.formItem.sceneImages)
            } else {
              this.imageForEach(this.formItem.resources.images)
            }
          }
        })
      },
      // 更多图片展示
      imageForEach (item) {
        this.images = []
        item.forEach((data) => {
          this.images.push({
            src: data.bucketUrl + data.fileUrl,
            msrc: data.bucketUrl + data.fileUrl + '?imageView2/2/w/300/h/300',
            w: data.w,
            h: data.h,
            title: data.name,
            id: data.id
          })
        })
      },
      setCurrentItem (item) {
        this.currentItem = {
          id: item.id,
          label: item.label
        }
      },
      getWarningInfo () {
        this.warningParams.deviceId = ''
        this.warningParams.facilityId = ''
        this.warningParams.type = this.currentItem.label
        if (this.currentItem.label === 'DEVICE') {
          this.warningParams.deviceId = this.currentItem.id
        }
        if (this.currentItem.label === 'FACILITY') {
          this.warningParams.facilityId = this.currentItem.id
        }
        this.$http(this, {
          url: SERVICE_URLS.equipment2.warningApi.search_for_map,
          noTips: true,
          data: this.warningParams,
          success: (data) => {
            this.warningInfoList = data.body.content
          }
        })
      },
      clickWarningInfo (id) {
        this.warningDetailModal = true
        this.$nextTick(() => {
          this.$refs.warningDetailRef.span = 12
          this.$refs.warningDetailRef.loadData(id)
        })
      },
      clickMoreWarningInfo () {
        this.warningModal = true
        this.$nextTick(() => {
          this.$refs.warningTableRef.searchParams.deviceName = undefined
          this.$refs.warningTableRef.searchParams.facilityId = undefined
          if (this.currentItem.label === 'DEVICE') {
            this.$refs.warningTableRef.searchParams.deviceName = this.formItem.name
          }
          if (this.currentItem.label === 'FACILITY') {
            this.$refs.warningTableRef.searchParams.facilityId = this.currentItem.id
          }
          this.$refs.warningTableRef.searchParams.mapId = this.mapId
          this.$refs.warningTableRef.search()
        })
      },
      moreVideo () {
        if (this.formItem.cameras.length > 0) {
          this.testCameraIndexCodes = []
          this.detailDrawer = true
          this.testCameraIndexCodes = this.formItem.cameras
          this.$nextTick(() => {
            this.$refs.hkPlayerRef.play()
          })
        } else {
          this.$message.warning('暂无视频')
        }
      },
      moreMetrics () {
        this.moreMetricsModal = true
        if (this.currentItem.label !== 'DEVICE') {
          this.$http(this, {
            url: SERVICE_URLS.equipment2.equipmentInfoApi.selectByFacilityId,
            noTips: true,
            params: {
              facilityId: this.currentItem.id
            },
            success: (data) => {
              this.$nextTick(() => {
                this.deviceSelect = data.body
                this.tempDeviceInfo = data.body[0]
                this.selectDevice(this.tempDeviceInfo)
              })
            }
          })
        } else {
          this.$nextTick(() => {
            this.$refs.moreMetricsRef.loadData(this.currentItem.id)
          })
        }
      },
      selectDevice (item) {
        this.tempDeviceInfo = item
        this.$refs.moreMetricsRef.loadData(item.id)
      },
      handleCheckInstanceType (item, clickType) {
        this.deviceSelectName = item.name
        const params = {
          deviceId: item.id
        }
        this.currentItem.id = item.id
        this.getClickItemInfo(params, this.getDeviceInfoAPI, clickType)
        this.select = true
      },
      loadDeviceByFacilityId (id) {
        this.$http(this, {
          url: SERVICE_URLS.equipment2.equipmentInfoApi.aggregationInfo2,
          noTips: true,
          loading: 'spinShow',
          params: {
            facilityId: id
          },
          success: (data) => {
            this.select = true
            this.currentItem.id = data.body.id
            this.currentItem.label = 'DEVICE'
            this.formItem = data.body
            this.deviceSelect = data.body.deviceSelect
            this.deviceSelectName = data.body.name
          }
        })
      },
      clickGatewayInfo () {
        this.gatewayModal = true
        this.$nextTick(() => {
          this.$refs.gatewayInfoRef.formItem = this.formItem
        })
      },
      clickEngineeringArchive () {
        this.engineeringArchiveModal = true
        this.$nextTick(() => {
          this.$refs.engineeringArchiveRef.formItem = this.formItem
        })
      },
      detailClose () {
        this.detailDrawer = false
        this.$refs.hkPlayerRef.closeHandle()
      },
      clickFlowChart () {
        window.open('/equipment2/technology/3d' + '?type=' + this.currentItem.label + '&id=' + this.currentItem.id,)
      },
      closeChart () {
        this.chartModal = false
      }
    }
  }
</script>
<style scoped>
  .banner-img {
    width: 100%;
    position: relative;
  }

  .image-top-btn {
    font-size: 12px;
    color: #ffffff;
    background-color: rgb(76, 69, 69);
    border: none;
    border-radius: 2px;
    outline: none;
    text-align: center;
  }

  .left_btn {
    position: absolute;
    left: 32px;
    top: 50px;
  }

  .right_btn {
    position: absolute;
    right: 35px;
    top: 50px;
  }
</style>
