<template>
  <a-row :gutter="16">
    <a-col :span="24" class="col-margin-padding">
      <div class="details" style="margin-top: 0px;">
        <div class="details-title" style="margin-top: 0;">
          <a-row>
            <a-col :span="17">
              <div class="details-title-text">各月巡检公里时长统计</div>
              <i class="details-title-text-i"></i>
            </a-col>
            <a-col :span="7">
              <YearPicker @selectYear="selectOnMonthYear" :defaultValue="selectOnMonthYearValue"></YearPicker>
              <PersonnelSelect ref="personnelRef" @onChange="selectUser"></PersonnelSelect>
            </a-col>
          </a-row>
        </div>
      </div>
      <a-spin :spinning="spinning">
        <a-row class="chart-content">
          <a-col :span="24">
            <div class="chart-title">{{selectOnMonthYearValue.format('YYYY') + '年' + onMonthName + '巡检次数统计'}}</div>
            <BarAndLine :data="onMonthData" id="barAndLine1"></BarAndLine>
          </a-col>
        </a-row>
      </a-spin>
    </a-col>
    <a-col :span="24">
      <div class="details" style="margin-top: 0px;">
        <div class="details-title" style="margin-top: 0;">
          <a-row>
            <a-col :span="17">
              <div class="details-title-text">各人巡检次数公里统计</div>
              <i class="details-title-text-i"></i>
            </a-col>
            <a-col :span="7">
              <YearPicker @selectYear="selectOnUserYear" :defaultValue="selectOnUserYearValue"></YearPicker>
              <MonthSelect ref="monthRef" @onChange="onChangeMonth" style="margin-left: 10px"></MonthSelect>
            </a-col>
          </a-row>
        </div>
      </div>
      <a-spin :spinning="spinning">
        <a-row class="chart-content">
          <a-col :span="24">
            <div class="chart-title">{{selectOnUserYearValue.format('YYYY') + '年' }}{{ selectMonth?selectMonth+'月':'' }}各人巡检次数公里统计</div>
            <BarAndLine :data="onUserData" id="barAndLine2"></BarAndLine>
          </a-col>
        </a-row>
      </a-spin>
    </a-col>
  </a-row>
</template>
<script>
  import moment from 'moment'
  import YearPicker from '../module/YearPicker'
  import BarAndLine from '../../statisticsChart/BarAndLine'
  import PersonnelSelect from '../module/PersonnelSelect'
  import MonthSelect from '../module/MonthSelect'
  import SERVICE_URLS from '@/api/service.url'

  export default {
    components: { YearPicker, BarAndLine, PersonnelSelect, MonthSelect },
    props: {
      mapId: {}
    },
    watch: {
      mapId (val) {
        this.mapId = val
      }
    },
    data () {
      return {
        spinning: false,
        inspectionTimesTab: '',
        mode: 'year',
        time: '',
        selectOnMonthYearValue: moment().endOf('year'),
        selectOnUserYearValue: moment().endOf('year'),
        onMonthName: '',
        selectMonth: '',
        onMonthData: [],
        onUserData: [],
        searchOnMonthParams: {
          mapId: this.mapId,
          startTimeStr: '',
          endTimeStr: '',
          xjMonth: '',
          countType: 0,
          userId: ''
        },
        searchOnUserParams: {
          mapId: this.mapId,
          startTimeStr: '',
          endTimeStr: '',
          xjMonth: '',
          countType: 1,
          userId: ''
        }

      }
    },
    created () {
      this.loadSearchOnMonthParams()
      this.searchOnMonth()
      this.loadSearchOnUserParams()
      this.searchOnUser()
    },
    methods: {
      moment,
      loadSearchOnMonthParams () {
        this.$nextTick(() => {
          this.$refs.personnelRef.userStr = undefined
        })
        this.searchOnMonthParams = {
          mapId: this.mapId,
          startTimeStr: this.getStartAndEndTimes(this.selectOnMonthYearValue, 'year')[0],
          endTimeStr: this.getStartAndEndTimes(this.selectOnMonthYearValue, 'year')[1],
          xjMonth: '',
          countType: 0,
          userId: ''
        }
      },
      // 获取开始时间结束时间
      getStartAndEndTimes (date, type) {
        const formatDate = new Date(date)
        const startTime = moment(formatDate).startOf(type).format('YYYY-MM-DD')
        const endTime = moment(formatDate).endOf(type).format('YYYY-MM-DD')
        return [startTime, endTime]
      },
      searchOnMonth () {
        this.$http(this, {
          url: SERVICE_URLS.xj.xjStatistics.count_inspect_mileage_and_time_with_month,
          noTips: true,
          data: this.searchOnMonthParams,
          success: (data) => {
            this.onMonthData = data.body.data
          }
        })
      },
      selectOnMonthYear (val) {
        this.selectOnMonthYearValue = val
        this.loadSearchOnMonthParams()
        this.searchOnMonth()
      },
      selectUser (userId) {
        this.searchOnMonthParams.userId = userId
        this.searchOnMonth()
      },
      // ========================= 按人统计
      loadSearchOnUserParams () {
        this.$nextTick(() => {
          this.$refs.monthRef.value = undefined
        })
        this.searchOnUserParams = {
          mapId: this.mapId,
          startTimeStr: this.getStartAndEndTimes(this.selectOnMonthYearValue, 'year')[0],
          endTimeStr: this.getStartAndEndTimes(this.selectOnMonthYearValue, 'year')[1],
          xjMonth: '',
          countType: 1,
          userId: ''
        }
      },
      searchOnUser () {
        this.$http(this, {
          url: SERVICE_URLS.xj.xjStatistics.count_inspect_mileage_and_time_with_user,
          noTips: true,
          data: this.searchOnUserParams,
          success: (data) => {
            this.onUserData = data.body.data
          }
        })
      },
      selectOnUserYear (val) {
        this.selectOnUserYearValue = val
        this.loadSearchOnUserParams()
        this.searchOnUser()
      },
      // 当选择月份处理为202006类型字符串
      isSelectMonth (e) {
        if (e < 10) {
          var month = '0' + e.toString()
        }
        const date = this.selectOnUserYearValue.format('YYYY') + '-' + month
        return date
      },
      onChangeMonth (value) {
        this.selectMonth = value
        if (value) {
          this.searchOnUserParams.xjMonth = this.isSelectMonth(value)
        } else {
          this.searchOnUserParams.xjMonth = ''
        }
        this.searchOnUser()
      }
    }
  }
</script>