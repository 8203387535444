<template>
  <a-row :gutter="16">
    <a-col :span="24" class="col-margin-padding">
      <div class="details" style="margin-top: 0px;">
        <div class="details-title" style="margin-top: 0;">
          <a-row>
            <a-col :span="20">
              <div class="details-title-text">
                {{selectYearValue.format('YYYY') + '年' + '各月拉运公里时长统计'}}
              </div>
              <i class="details-title-text-i"></i>
            </a-col>
            <a-col :span="4">
              <YearPicker @selectYear="selectTimeYear" :defaultValue="selectYearValue"></YearPicker>
            </a-col>
          </a-row>
        </div>
      </div>
      <a-spin :spinning="spinning1">
        <div class="ant-table-wrapper">
          <div class="ant-spin-nested-loading">
            <div class="ant-table ant-table-scroll-position-left ant-table-default ant-table-bordered">
              <div class="ant-table-content">
                <div class="ant-table-body">
                  <table v-if="everyYearData.length>0" style="text-align: center;width: 100%">
                    <thead class="ant-table-thead">
                    <tr>
                      <th style="text-align: center;width: 130px">月份</th>
                      <th style="text-align: center" v-for="item in 12">
                        {{item}}月
                      </th>
                    </tr>
                    </thead>
                    <tbody class="ant-table-tbody">
                    <tr class="ant-table-row ant-table-row-level-0">
                      <td class="ant-table-row-cell-break-word">拉运次数</td>
                      <td class="ant-table-row-cell-break-word" v-for="i in everyYearDataCount">
                        {{i}}
                      </td>
                    </tr>
                    <tr class="ant-table-row ant-table-row-level-0">
                      <td class="ant-table-row-cell-break-word">拉运时长</td>
                      <td v-for="i in everyYearDataTime">
                        {{i}}
                      </td>
                    </tr>
                    <tr class="ant-table-row ant-table-row-level-0">
                      <td class="ant-table-row-cell-break-word">拉运公里</td>
                      <td v-for="i in everyYearDataKm">
                        {{i.toFixed(2)}}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <a-empty v-else/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </a-spin>
    </a-col>
    <a-col :span="24" class="col-margin-padding">
      <div class="details" style="margin-top: 0px;">
        <div class="details-title" style="margin-top: 0;">
          <a-row>
            <a-col :span="14">
              <div class="details-title-text">{{selectYearValue.format('YYYY') + '年' + '各司机拉运情况统计'}}</div>
              <i class="details-title-text-i"></i>
            </a-col>
          </a-row>
        </div>
      </div>
      <a-spin :spinning="spinning2">
        <div class="ant-table-wrapper">
          <div class="ant-spin-nested-loading">
            <div class="ant-table ant-table-scroll-position-left ant-table-default ant-table-bordered">
              <div class="ant-table-content">
                <div class="ant-table-body">
                  <table v-if="everyMonthData.length>0" style="text-align: center;width: 100%">
                    <thead class="ant-table-thead">
                    <tr>
                      <th style="text-align: center;width: 130px">姓名</th>
                      <th style="text-align: center">月份</th>
                      <th style="text-align: center" v-for="item in 12">
                        {{item}}月
                      </th>
                    </tr>
                    </thead>
                    <tbody class="ant-table-tbody">
                    <template v-for="item in everyMonthData">
                      <tr class="ant-table-row ant-table-row-level-0">
                        <td rowspan="4">{{item.context}}</td>
                      </tr>
                      <tr class="ant-table-row ant-table-row-level-0">
                        <td class="ant-table-row-cell-break-word">拉运次数</td>
                        <td class="ant-table-row-cell-break-word" v-for="i in 12">
                          <span v-if="parseInt((item.item).split('-')[1]) === i">{{item.count}}</span>
                          <span v-else>0</span>
                        </td>
                      </tr>
                      <tr class="ant-table-row ant-table-row-level-0">
                        <td class="ant-table-row-cell-break-word">拉运时长(h)</td>
                        <td class="ant-table-row-cell-break-word" v-for="i in 12">
                          <span v-if="parseInt((item.item).split('-')[1]) === i"> {{item.duration}}</span>
                          <span v-else>0</span>
                        </td>
                      </tr>
                      <tr class="ant-table-row ant-table-row-level-0">
                        <td class="ant-table-row-cell-break-word">拉运公里(km)</td>
                        <td class="ant-table-row-cell-break-word" v-for="i in 12">
                          <span v-if="parseInt((item.item).split('-')[1]) === i"> {{item.kilometers.toFixed(2)}}</span>
                          <span v-else>0</span>
                        </td>
                      </tr>
                    </template>
                    </tbody>
                  </table>
                  <a-empty v-else/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </a-spin>
    </a-col>
    <a-col :span="24" class="col-margin-padding">
      <div class="details" style="margin-top: 0px;">
        <div class="details-title" style="margin-top: 0;">
          <a-row>
            <a-col :span="14">
              <div class="details-title-text">{{selectYearValue.format('YYYY') + '年' + '各车辆拉运情况统计'}}</div>
              <i class="details-title-text-i"></i>
            </a-col>
          </a-row>
        </div>
      </div>
      <a-spin :spinning="spinning3">
        <div class="ant-table-wrapper">
          <div class="ant-spin-nested-loading">
            <div class="ant-table ant-table-scroll-position-left ant-table-default ant-table-bordered">
              <div class="ant-table-content">
                <div class="ant-table-body">
                  <table v-if="carData.length>0" style="text-align: center;width: 100%">
                    <thead class="ant-table-thead">
                    <tr>
                      <th style="text-align: center;width: 130px">车辆</th>
                      <th style="text-align: center">月份</th>
                      <th style="text-align: center" v-for="item in 12">
                        {{item}}月
                      </th>
                    </tr>
                    </thead>
                    <tbody class="ant-table-tbody">
                    <template v-for="item in carData">
                      <tr class="ant-table-row ant-table-row-level-0">
                        <td rowspan="4">{{item.context}}</td>
                      </tr>
                      <tr class="ant-table-row ant-table-row-level-0">
                        <td class="ant-table-row-cell-break-word">拉运次数</td>
                        <td class="ant-table-row-cell-break-word" v-for="i in 12">
                          <span v-if="parseInt((item.item).split('-')[1]) === i">{{item.count}}</span>
                          <span v-else>0</span>
                        </td>
                      </tr>
                      <tr class="ant-table-row ant-table-row-level-0">
                        <td class="ant-table-row-cell-break-word">拉运时长(h)</td>
                        <td class="ant-table-row-cell-break-word" v-for="i in 12">
                          <span v-if="parseInt((item.item).split('-')[1]) === i"> {{item.duration}}</span>
                          <span v-else>0</span>
                        </td>
                      </tr>
                      <tr class="ant-table-row ant-table-row-level-0">
                        <td class="ant-table-row-cell-break-word">拉运公里(km)</td>
                        <td class="ant-table-row-cell-break-word" v-for="i in 12">
                          <span v-if="parseInt((item.item).split('-')[1]) === i"> {{item.kilometers.toFixed(2)}}</span>
                          <span v-else>0</span>
                        </td>
                      </tr>
                    </template>
                    </tbody>
                  </table>
                  <a-empty v-else/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </a-spin>
    </a-col>
  </a-row>
</template>
<script>
  import moment from 'moment'
  import YearPicker from '../module/YearPicker'
  import SERVICE_URLS from '@/api/service.url'

  export default {
    components: { YearPicker },
    props: {
      mapId: {}
    },
    data () {
      return {
        spinning: false,
        selectYearValue: moment().endOf('year'),
        selectMonth: undefined,
        everyYearData: [],
        everyYearDataCount: Array(12).fill(0),
        everyYearDataTime: Array(12).fill(0),
        everyYearDataKm: Array(12).fill(0),
        selectOneCarName: '',
        searchYearParams: {
          carInforIdOrUserId: undefined,
          groupType: 1,
          mapId: this.mapId,
          statistisContentType: '1,2,3',
          statistisType: 1,
          timeStr: ''
        },
        everyMonthData: [],
        everyMonthDataCount: Array(12).fill(0),
        everyMonthDataTime: Array(12).fill(0),
        everyMonthDataKm: Array(12).fill(0),
        searchMonthParams: {
          carInforIdOrUserId: undefined,
          groupType: 1, //按分组类型 1 月份 2 按分组类型
          mapId: this.mapId,
          statistisContentType: '1,2,3',
          statistisType: 2, // 类型 1车辆 2司机
          timeStr: ''
        },
        carData: [],
        carDataCount: Array(12).fill(0),
        carDataTime: Array(12).fill(0),
        carDataKm: Array(12).fill(0),
        searchCarParams: {
          carInforIdOrUserId: undefined,
          groupType: 1, //按分组类型 1 月份 2 按分组类型
          mapId: this.mapId,
          statistisContentType: '1,2,3',
          statistisType: 1, // 类型 1车辆 2司机
          timeStr: ''
        },
        spinning1: true,
        spinning2: true,
        spinning3: true
      }
    },
    watch: {
      mapId (val) {
        this.macId = val
      }
    },
    created () {
      this.loadYearParams()
      this.loadMonthParams()
      this.loadCarParams()
      this.searchYear()
      this.searchMonth()
      this.searchCar()
    },
    methods: {
      moment,
      selectTimeYear (val) {
        this.selectYearValue = val
        this.loadYearParams()
        this.loadMonthParams()
        this.loadCarParams()
        this.searchYear()
        this.searchMonth()
        this.searchCar()
      },
      loadYearParams () {
        this.searchYearParams = {
          carInforIdOrUserId: '',
          groupType: 1,
          mapId: this.mapId,
          statistisContentType: '1,2,3',
          statistisType: '',
          timeStr: this.selectYearValue.format('YYYY')
        }
      },
      searchYear () {
        this.spinning1 = true
        this.$http(this, {
          url: SERVICE_URLS.car.carStatisticsApi.statistics,
          noTips: true,
          data: this.searchYearParams,
          success: (data) => {
            this.everyYearData = data.body.data
            this.everyYearData.forEach((x) => {
              const e = parseInt((x.item).split('-')[1]) - 1
              this.everyYearDataCount[e] = x.count
              this.everyYearDataTime[e] = x.duration
              this.everyYearDataKm[e] = x.kilometers
            })
            this.spinning1 = false
          }
        })
      },
      loadCarParams () {
        this.searchCarParams = {
          carInforIdOrUserId: undefined,
          groupType: 1, //按分组类型 1 月份 2 按分组类型
          mapId: this.mapId,
          statistisContentType: '1,2,3',
          statistisType: 1, // 类型 1车辆 2司机
          timeStr: this.selectYearValue.format('YYYY')
        }
      },
      searchCar () {
        this.spinning2 = true
        this.$http(this, {
          url: SERVICE_URLS.car.carStatisticsApi.statistics,
          noTips: true,
          data: this.searchCarParams,
          success: (data) => {
            this.carData = data.body.data
            this.spinning2 = false
          }
        })
      },
      // 月份查询
      loadMonthParams () {
        this.searchMonthParams = {
          carInforIdOrUserId: '',
          groupType: 1, //按分组类型 1 月份 2 按分组类型
          mapId: this.mapId,
          statistisContentType: '1,2,3',
          statistisType: 2, // 类型 1车辆 2司机
          timeStr: this.selectYearValue.format('YYYY')
        }
      },
      searchMonth () {
        this.spinning3 = true
        this.$http(this, {
          url: SERVICE_URLS.car.carStatisticsApi.statistics,
          noTips: true,
          data: this.searchMonthParams,
          success: (data) => {
            this.everyMonthData = data.body.data
            this.spinning3 = false
          }
        })
      }

    }
  }
</script>