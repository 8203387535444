<template>
  <div>
    <div class="fs-search-box" style="border-bottom: 1px solid #e8e8e8;margin-bottom: 10px">
      <div class="table-page-search-wrapper" style="margin-bottom: 10px">
        <a-form layout="inline">
          <a-form-item label="巡检人">
            <a-tree-select
              allowClear
              :treeData="userList"
              v-model="userStr"
              @change="troubleSearch"
              placeholder="请选择巡检人"
            >
            </a-tree-select>
          </a-form-item>
          <a-form-item label="巡检结果">
            <a-radio-group @change="selectResult" v-model="searchParams.recordResult">
              <a-radio-button value="">全部</a-radio-button>
              <a-radio-button value="NORMAL">正常</a-radio-button>
              <a-radio-button value="ABNORMAL">异常</a-radio-button>
            </a-radio-group>
          </a-form-item>
          <a-form-item>
            <a-radio-group style="margin-right: 5px;" @change="handleChangeMetricsHistoryDate" v-model="dateSelect">
              <a-radio-button value="day">今日</a-radio-button>
              <a-radio-button value="week">本周</a-radio-button>
              <a-radio-button value="month">本月</a-radio-button>
              <a-radio-button value="year">全年</a-radio-button>
            </a-radio-group>
            <a-range-picker
              :value="metricsHistoryTime"
              :allowClear="false"
              :placeholder="['开始时间', '结束时间']"
              format="YYYY-MM-DD"
              @ok="handleModalPickerOk"
              @change="(value) => (metricsHistoryTime = value)"
            ></a-range-picker>
          </a-form-item>
          <a-form-item class="f-r m-r-none">
            <a-button type="primary" @click="troubleSearch" icon="search">查询</a-button>
            <a-button class="m-l-6" @click="resetForm" icon="reload">重置</a-button>
            <a :style="{ marginLeft: '5px', fontSize: '12px' }" @click="toggle">
              {{ expand ? '收起' : '展开' }}
              <a-icon :type="expand ? 'up' : 'down'"/>
            </a>
          </a-form-item>
          <a-row v-if="expand" style="margin-top: 10px;">
            <a-form-item label="所属部门">
              <a-tree-select
                allowClear
                :treeData="deptList"
                v-model="searchParams.departmentId"
                @change="troubleSearch"
                placeholder="请选择巡检部门"
              >
              </a-tree-select>
            </a-form-item>
          </a-row>
        </a-form>
      </div>
    </div>
    <a-table
      size="middle"
      :columns="columns"
      :dataSource="tableData"
      :loading="tableLoading"
      :pagination="pagination"
      @change="tableChange"
      :customRow="click"
      :rowKey="(record) => record.id"
    >
      <template slot="_index" slot-scope="text, record, index">
        {{ searchParams.number * searchParams.size + index + 1 }}
      </template>
      <template slot="time" slot-scope="text, record"> {{ record.time }}123</template>
      <template slot="result" slot-scope="text, record">
        <a-tag color="blue" v-if="record.result === 'NORMAL'">正常</a-tag>
        <a-tag color="red" v-if="record.result === 'ABNORMAL'">异常</a-tag>
      </template>
      <template slot="finish" slot-scope="text, record">
        <span v-if="record.finish === 'FINISHED'">完成</span>
        <span v-if="record.finish === 'UNFINISHED'">未完成</span>
      </template>
    </a-table>
    <a-drawer title="巡检详情" :visible="infoModal" :footer="null" @close="closeModal" :width="600" :mask="false">
      <xunjian-passed ref="passedRef" :mapHeight="350" :dbDevicesData="dbDevicesData"></xunjian-passed>
    </a-drawer>
  </div>
</template>

<script>
  import entityCRUDWithCopy from '../../../common/mixins/entityCRUDWithCopy'
  import mapDevices from '../../../map/composite/mixins/map-devices'

  import SERVICE_URLS from '@/api/service.url'
  import { inquireColumns } from './../../../xj/inquire/common/common'
  import moment from 'moment'
  import XunjianPassed from '../../../map/composite/XunjianPassed'

  export default {
    name: 'myInspection',
    mixins: [entityCRUDWithCopy, mapDevices],
    components: { XunjianPassed },
    data () {
      return {
        valueStyle: {
          color: '',
          textAlign: 'center'
        },
        entityBaseUrl: SERVICE_URLS.xj.xjRecord,
        deptList: [],
        userList: [],
        searchParams: {
          number: '0',
          size: '10',
          direction: '',
          order: '',
          recordResult: '',
          startTime: '',
          endTime: '',
          userId: undefined,
          departmentId: undefined
        },
        initColumns: inquireColumns(),
        infoModal: false,
        metricsHistoryTime: [moment().startOf('day'), moment().endOf('day')],
        inspectionStatistics: {},
        dateSelect: 'day',
        spinning: true,
        userStr: undefined,
        expand: false,
      }
    },
    created () {
      this.getCountTimes()
      this.loadDept()
      this.loadUser()
      this.searchParams.startTime = this.metricsHistoryTime[0].format('YYYY-MM-DD')
      this.searchParams.endTime = this.metricsHistoryTime[1].format('YYYY-MM-DD')
    },
    methods: {
      toggle() {
        this.expand = !this.expand
      },
      handleChangeMetricsHistoryDate (e) {
        this.metricsHistoryTime = [moment().startOf(e.target.value), moment().endOf(e.target.value)]
        this.searchParams.startTime = this.metricsHistoryTime[0].format('YYYY-MM-DD')
        this.searchParams.endTime = this.metricsHistoryTime[1].format('YYYY-MM-DD')
        this.search()
        this.getCountTimes()
      },
      handleModalPickerOk (value) {
        this.searchParams.startTime = value[0].format('YYYY-MM-DD')
        this.searchParams.endTime = value[1].format('YYYY-MM-DD')
        this.search()
        this.getCountTimes()
      },
      click (record, index) {
        return {
          on: {
            click: () => {
              this.infoModal = true
              this.$nextTick(() => {
                this.$refs.passedRef.loadData(record.id)
              })
            }
          }
        }
      },
      closeModal () {
        this.infoModal = false
      },
      selectResult () {
        this.search()
        this.getCountTimes()
      },
      getCountTimes () {
        this.spinning = true
        this.$http(this, {
          url: SERVICE_URLS.xj.xjRecord.count_times,
          data: this.searchParams,
          noTips: true,
          success: (data) => {
            this.inspectionStatistics = data.body
            this.spinning = false
          }
        })
      },
      loadDept () {
        this.$http(this, {
          url: SERVICE_URLS.organization.antTree,
          noTips: true,
          success: (data) => {
            this.deptList = data.body
          }
        })
      },
      loadUser () {
        this.$http(this, {
          url: SERVICE_URLS.organization.orgUserTree,
          data: {
            authPrefix: 'org_',
            chooseType: 'RADIO'
          },
          noTips: true,
          success: (data) => {
            this.userList = data.body
          }
        })
      },
      troubleSearch () {
        this.searchParams.userId = this.userStr ? this.userStr.split('_')[2] : undefined
        this.search()
        this.getCountTimes()
      },
      resetForm () {
        this.userStr = undefined
        this.searchParams = {
          number: '0',
          size: '10',
          direction: '',
          order: '',
          recordResult: '',
          startTime: '',
          endTime: '',
          userId: undefined,
          departmentId: undefined
        }
        this.dateSelect = ''
        this.metricsHistoryTime = []
        this.search()
        this.getCountTimes()
      }
    }
  }
</script>
