<template>
  <a-row :gutter="16">
    <a-col :span="24" class="col-margin-padding">
      <div class="details" style="margin-top: 0px;">
        <div class="details-title" style="margin-top: 0;">
          <a-row>
            <a-col :span="17">
              <div class="details-title-text">拉运次数统计</div>
              <i class="details-title-text-i"></i>
            </a-col>
            <a-col :span="7">
              <YearPicker @selectYear="selectTimeYear" :defaultValue="selectTimeYearValue"></YearPicker>
            </a-col>
          </a-row>
        </div>
      </div>
      <a-spin :spinning="spinning">
        <a-row class="chart-content">
          <a-col :span="12">
            <div class="chart-title">{{selectTimeYearValue.format('YYYY') + '年'}}各车辆拉运次数统计</div>
            <PieChartCar2 :data="everyCarData" xName="context" :count="everyCarCount" id="pie1" :chartOnClick="true" @clickPie="clickCarPie"></PieChartCar2>
          </a-col>
          <a-col :span="12">
            <div class="chart-title">{{selectTimeYearValue.format('YYYY') + '年' + selectOneCarName + '拉运次数统计'}}</div>
            <PieChartCar2 :data="oneCarData" xName="item" :count="oneCarCount" id="pie2" :legend="true"></PieChartCar2>
          </a-col>
        </a-row>
      </a-spin>
    </a-col>
    <a-col :span="24">
      <a-spin :spinning="spinning">
        <a-row class="chart-content">
          <a-col :span="12">
            <div class="chart-title">{{selectTimeYearValue.format('YYYY') + '年各人拉运次数统计'}}</div>
            <PieChartCar2 :data="driverData" xName="context" :count="driverCount" id="pie3" :chartOnClick="true" @clickPie="clickDriverPie"></PieChartCar2>
          </a-col>
          <a-col :span="12">
            <div class="chart-title">{{selectTimeYearValue.format('YYYY') + '年' + selectDriverName + '拉运次数统计'}}</div>
            <PieChartCar2 :data="oneDriverData" :count="oneDriverCount" id="pie4" :legend="true"></PieChartCar2>
          </a-col>
        </a-row>
      </a-spin>
    </a-col>
  </a-row>
</template>
<script>
  import moment from 'moment'
  import YearPicker from '../module/YearPicker'
  import PieChartCar2 from '../../statisticsChart/PieChartCar2'
  import SERVICE_URLS from '@/api/service.url'

  export default {
    components: { YearPicker, PieChartCar2 },
    props: {
      mapId: {}
    },
    data () {
      return {
        spinning: false,
        selectTimeYearValue: moment().endOf('year'),
        selectMonth: undefined,
        everyCarData: [],
        everyCarCount: '',
        oneCarData: [],
        oneCarCount: '',
        selectOneCarName: '',
        searchCarParams: {
          carInforIdOrUserId: '',
          groupType: 2,
          mapId: this.mapId,
          statistisContentType: '1',
          statistisType: 1,
          timeStr: ''
        },
        searchDriverParams: {
          carInforIdOrUserId: '',
          groupType: 2, //按分组类型 1 月份 2 按分组类型
          mapId: this.mapId,
          statistisContentType: '1',
          statistisType: 2, // 类型 1车辆 2司机
          timeStr: ''
        },
        driverData: [],
        driverCount: '',
        selectDriverName: '',
        oneDriverCount: '',
        oneDriverData: []
      }
    },
    watch: {
      mapId (val) {
        this.macId = val
      }
    },
    created () {
      this.load()
      this.searchEveryCar()
      this.searchDriver()
    },
    methods: {
      moment,
      load () {
        this.searchCarParams = {
          carInforIdOrUserId: '',
          groupType: 2,
          mapId: this.mapId,
          statistisContentType: '1',
          statistisType: 1,
          timeStr: this.selectTimeYearValue.format('YYYY')
        }
        this.searchDriverParams = {
          carInforIdOrUserId: '',
          groupType: 2, //按分组类型 1 月份 2 按分组类型
          mapId: this.mapId,
          statistisContentType: '1',
          statistisType: 2, // 类型 1车辆 2司机
          timeStr: this.selectTimeYearValue.format('YYYY')
        }
      },
      searchEveryCar () {
        this.$http(this, {
          url: SERVICE_URLS.car.carStatisticsApi.statistics,
          noTips: true,
          data: this.searchCarParams,
          success: (data) => {
            if (data.body.data.length > 0) {
              this.everyCarCount = this.getCount(data.body.data)
              this.everyCarData = this.getPercent(data.body.data, this.everyCarCount)
              this.searchCarParams.carInforIdOrUserId = data.body.data[0].item
              this.selectOneCarName = data.body.data[0].context
            } else {
              this.everyCarCount = ''
              this.everyCarData = []
            }
            this.searchCarParams.groupType = 1
            this.searchOneCar()
          }
        })
      },
      searchOneCar () {
        this.$http(this, {
          url: SERVICE_URLS.car.carStatisticsApi.statistics,
          noTips: true,
          data: this.searchCarParams,
          success: (data) => {
            if (data.body.data.length > 0) {
              this.oneCarCount = this.getCount(data.body.data)
              this.oneCarData = this.getPercent(data.body.data, this.oneCarCount)
            } else {
              this.oneCarCount = ''
              this.oneCarData = []
            }
          }
        })
      },
      searchDriver () {
        this.$http(this, {
          url: SERVICE_URLS.car.carStatisticsApi.statistics,
          noTips: true,
          data: this.searchDriverParams,
          success: (data) => {
            if (data.body.data.length > 0) {
              this.driverCount = this.getCount(data.body.data)
              this.driverData = this.getPercent(data.body.data, this.driverCount)
              this.searchDriverParams.carInforIdOrUserId = data.body.data[0].item
              this.selectDriverName = data.body.data[0].context
            } else {
              this.driverCount = ''
              this.driverData = []
            }
            this.searchDriverParams.groupType = 1
            this.searchOneDriver()
          }
        })
      },
      searchOneDriver () {
        this.$http(this, {
          url: SERVICE_URLS.car.carStatisticsApi.statistics,
          noTips: true,
          data: this.searchDriverParams,
          success: (data) => {
            if (data.body.data.length > 0) {
              this.oneDriverCount = this.getCount(data.body.data)
              this.oneDriverData = this.getPercent(data.body.data, this.oneDriverCount)
            } else {
              this.oneDriverCount = ''
              this.oneDriverData = []
            }
          }
        })
      },
      // 计算总次数
      getCount (data) {
        var s = 0
        for (var i = 0; i < data.length; i++) {
          s += data[i].count
        }
        return s
      },
      // 计算车辆次数占比
      getPercent (data, cunt) {
        for (var j = 0; j < data.length; j++) {
          data[j].percent = parseFloat((data[j].count / cunt).toFixed(2))
        }
        return data
      },
      selectTimeYear (val) {
        this.selectTimeYearValue = val
        this.load()
        this.searchEveryCar()
        this.searchDriver()
      },
      selectUser (userId) {

      },
      onChangeSelect () {

      },
      clickCarPie (e) {
        this.searchCarParams.groupType = 1
        this.searchCarParams.carInforIdOrUserId = e.item
        this.selectOneCarName = e.context
        this.searchOneCar()
      },
      clickDriverPie (e) {
        this.searchDriverParams.groupType = 1
        this.searchDriverParams.carInforIdOrUserId = e.item
        this.selectDriverName = e.context
        this.searchOneDriver()
      }

    }
  }
</script>
<style lang="less">
  .chart-title {
    font-size: 15px;
    width: 100%;
    text-align: center;
    line-height: 15px;
    font-weight: bolder;
  }

  .col-margin-padding {
    /*padding: 10px;*/
    margin-bottom: 30px;
  }

  .chart-content {
    margin-top: 20px;
    padding: 10px;
  }
</style>