<template>
  <a-spin :spinning="spinning">
    <div class="details">
      <div class="details-title">
        <div class="details-title-text">基础信息</div>
        <i class="details-title-text-i"></i>
      </div>
      <template>
        <a-row class="" v-if="formItem.engineeringArchive">
          <template v-if="formItem.engineeringArchive.extendInfos.length > 0">
            <a-col :span="8" v-for="(item, index) in formItem.engineeringArchive.extendInfos" :key="index">
              <span :title="item.ikey">{{item.ikey.length>10?item.ikey.substring(0,10)+'...': item.ikey}}：</span> {{ item.value }}
            </a-col>
          </template>
          <div class="details-content-rests" v-else>
            <div class="details-content-rests-noImageState" style="height: 50px;">
              <a-icon class="details-content-rests-noImageState-icon" type="smile"/>
              <span>暂无基础信息</span>
            </div>
          </div>
        </a-row>
      </template>
      <div class="details-title">
        <div class="details-title-text">文件信息</div>
        <i class="details-title-text-i"></i>
      </div>
      <div class="details-content-rests" v-if="formItem.engineeringArchive">
        <template v-if="formItem.engineeringArchive.files.length > 0">
          <qiniu-file-upload
            ref="qiniuFileRef"
            type="view"
            :items="formItem.engineeringArchive.files"
          ></qiniu-file-upload>
        </template>
        <template v-else>
          <div class="details-content-rests-noImageState" style="height: 50px;">
            <a-icon class="details-content-rests-noImageState-icon" type="smile"/>
            <span>暂未上传文件</span>
          </div>
        </template>
      </div>
      <div class="details-title">
        <div class="details-title-text">图片信息</div>
        <i class="details-title-text-i"></i>
      </div>
      <div class="details-content-rests" v-if="formItem.engineeringArchive">
        <template v-if="formItem.engineeringArchive.images.length > 0">
          <qiniu-image-upload ref="qiniuImageRef" type="view" :items="formItem.engineeringArchive.images"/>
        </template>
        <template v-else>
          <div
            style="
              color: #c5c8ce;
              font-size: 16px;
              height: 50px;
              display: flex;
              justify-content: center;
              align-items: center;
            "
          >
            <a-icon style="margin-right: 5px;" type="smile"/>
            <span>暂未上传图片</span>
          </div>
        </template>
      </div>
    </div>
  </a-spin>
</template>

<script>
  import QiniuImageUpload from '@/components/QiniuUpload/QiniuImageUpload'
  import QiniuFileUpload from '@/components/QiniuUpload/QiniuFileUpload'

  export default {
    components: { QiniuImageUpload, QiniuFileUpload },
    props: {},
    data () {
      return {
        spinning: false,
        formItem: {}
      }
    },
    methods: {}
  }
</script>
