<template>
  <a-row :gutter="16">
    <a-col :span="24" class="col-margin-padding">
      <div class="details" style="margin-top: 0px;">
        <div class="details-title" style="margin-top: 0;">
          <a-row>
            <a-col :span="17">
              <div class="details-title-text">巡检次数统计</div>
              <i class="details-title-text-i"></i>
            </a-col>
            <a-col :span="7">
              <YearPicker @selectYear="selectXunjianTimeYear" :defaultValue="selectXunjianTimeYearValue"></YearPicker>
              <PersonnelSelect @onChange="selectUser"></PersonnelSelect>
            </a-col>
          </a-row>
        </div>
      </div>
      <a-spin :spinning="spinning">
        <a-row class="chart-content">
          <a-col :span="8">
            <div class="chart-title">{{selectXunjianTimeYearValue.format('YYYY') + '年'}} {{ name?name:'' }}各月巡检次数统计</div>
            <PieXunjian :data="data[0]" id="xunjianTimes" xName="context" :chartOnClick="true"  @clickPie="clickPie"></PieXunjian>
          </a-col>
          <a-col :span="8">
            <div class="chart-title">{{selectXunjianTimeYearValue.format('YYYY') + '年' }} {{ name?name:'' }}巡检点巡检次数统计</div>
            <PieXunjian :data="data[1]" id="xunjianNodeTimes" ></PieXunjian>
          </a-col>
          <a-col :span="8">
            <div class="chart-title">{{selectXunjianTimeYearValue.format('YYYY') + '年' }}{{ name?name:'' }}巡检点工单处理情况</div>
            <PieXunjian :data="data[2]" id="xunjianNodeTicketTimes" ></PieXunjian>
          </a-col>
        </a-row>
      </a-spin>
    </a-col>
    <a-col :span="24">
      <div class="details" style="margin-top: 0px;">
        <div class="details-title" style="margin-top: 0;">
          <a-row>
            <a-col :span="17">
              <div class="details-title-text">各人巡检次数统计</div>
              <i class="details-title-text-i"></i>
            </a-col>
            <a-col :span="7">
              <YearPicker @selectYear="selectEveryOneXunjianTimeYear" :defaultValue="selectEveryOneXunjianTimeYearValue"></YearPicker>
              <MonthSelect @onChange="onChangeMonth" style="margin-left: 10px"></MonthSelect>
            </a-col>
          </a-row>
        </div>
      </div>
      <a-spin :spinning="spinning">
        <a-row class="chart-content">
          <a-col :span="8">
            <div class="chart-title">{{selectXunjianTimeYearValue.format('YYYY') + '年'}}{{selectMonth?selectMonth+'月':''}} 各人巡检次数统计</div>
            <PieXunjian :data="everyOneData[0]" id="everyoneXunjianTimes" :chartOnClick="true"  @clickPie="clickEvertOneDataPie"></PieXunjian>
          </a-col>
          <a-col :span="8">
            <div class="chart-title">{{selectXunjianTimeYearValue.format('YYYY') + '年' }}{{selectMonth?selectMonth+'月':''}} 各人巡检次数统计</div>
            <PieXunjian :data="everyOneData[1]" id="everyoneXunjianNodeTimes" ></PieXunjian>
          </a-col>
          <a-col :span="8">
            <div class="chart-title">{{selectXunjianTimeYearValue.format('YYYY') + '年'}}{{selectMonth?selectMonth+'月':''}} 各人巡检次数统计</div>
            <PieXunjian :data="everyOneData[2]" id="everyoneXunjianNodeTicketTimes" ></PieXunjian>
          </a-col>
        </a-row>
      </a-spin>
    </a-col>
  </a-row>
</template>
<script>
  import moment from 'moment'
  import YearPicker from '../module/YearPicker'
  import PieXunjian from '../../statisticsChart/PieXunjian'
  import PersonnelSelect from '../module/PersonnelSelect'
  import MonthSelect from '../module/MonthSelect'
  import SERVICE_URLS from '@/api/service.url'

  export default {
    components: { YearPicker, PieXunjian, PersonnelSelect, MonthSelect },
    props: {
      mapId: {}
    },
    watch: {
      mapId (val) {
        this.mapId = val
      }
    },
    data () {
      return {
        spinning: false,
        selectXunjianTimeYearValue: moment().endOf('year'),
        name: '',
        selectMonth: '',
        data: [],
        searchXunjianTimeParams: {
          mapId: this.mapId,
          startTimeStr: '',
          endTimeStr: '',
          xjMonth: '',
          countType: 0,
          userId: '',
          name: ''
        },
        everyOneData: [],
        everyOneName: '',
        selectEveryOneXunjianTimeYearValue: moment().endOf('year'),
        searchEveryOneXunjianTimeParams: {
          mapId: this.mapId,
          startTimeStr: '',
          endTimeStr: '',
          xjMonth: '',
          countType: 1,
          userId: '',
          name: ''
        }
      }
    },
    created () {
      this.loadSearchXunjianTimeParams()
      this.searchXunjianTimeData()
      this.loadsearchEveryOneXunjianTimeParams()
      this.searchEveryOneXunjianTimeData()
    },
    methods: {
      moment,
      loadSearchXunjianTimeParams () {
        this.searchXunjianTimeParams = {
          mapId: this.mapId,
          startTimeStr: this.getStartAndEndTimes(this.selectXunjianTimeYearValue, 'year')[0],
          endTimeStr: this.getStartAndEndTimes(this.selectXunjianTimeYearValue, 'year')[1],
          xjMonth: '',
          countType: 0,
          userId: '',
          name: ''
        }
      },
      searchXunjianTimeData () {
        this.$http(this, {
          url: SERVICE_URLS.xj.xjStatistics.count_inspect_num_with_month,
          noTips: true,
          data: this.searchXunjianTimeParams,
          success: (data) => {
            this.data = data.body
          }
        })
      },
      selectXunjianTimeYear (val) {
        this.selectXunjianTimeYearValue = val
        this.loadSearchXunjianTimeParams()
        this.searchXunjianTimeData()
      },
      selectUser (userId, label) {
        this.name = label
        console.log(userId)
        this.searchXunjianTimeParams.userId = userId
        this.searchXunjianTimeData()
      },
      clickPie (item) {
        this.searchXunjianTimeParams.xjMonth = item.context
        this.searchXunjianTimeData()
      },
      // 当选择月份处理为202006类型字符串
      isSelectMonth (e) {
        if (e < 10) {
          var month = '0' + e.toString()
        }
        const date = this.selectXunjianTimeYearValue.format('YYYY') + '-' + month
        return date
      },
      // 获取开始时间结束时间
      getStartAndEndTimes (date, type) {
        const formatDate = new Date(date)
        const startTime = moment(formatDate).startOf(type).format('YYYY-MM-DD')
        const endTime = moment(formatDate).endOf(type).format('YYYY-MM-DD')
        return [startTime, endTime]
      },
      // ===================================
      loadsearchEveryOneXunjianTimeParams () {
        this.searchEveryOneXunjianTimeParams = {
          mapId: this.mapId,
          startTimeStr: this.getStartAndEndTimes(this.selectXunjianTimeYearValue, 'year')[0],
          endTimeStr: this.getStartAndEndTimes(this.selectXunjianTimeYearValue, 'year')[1],
          xjMonth: '',
          countType: 1,
          userId: '',
          name: ''
        }
      },
      searchEveryOneXunjianTimeData () {
        this.$http(this, {
          url: SERVICE_URLS.xj.xjStatistics.count_inspect_num_with_user,
          noTips: true,
          data: this.searchEveryOneXunjianTimeParams,
          success: (data) => {
            this.everyOneData = data.body
          }
        })
      },
      onChangeMonth (value) {
        this.selectMonth = value
        if (value) {
          this.searchEveryOneXunjianTimeParams.xjMonth = this.isSelectMonth(value)
        } else {
          this.searchEveryOneXunjianTimeParams.xjMonth = ''
        }
        this.searchEveryOneXunjianTimeData()
      },
      clickEvertOneDataPie (e) {
        this.everyOneName = e.item
        this.searchEveryOneXunjianTimeParams.userId = e.context
        this.searchEveryOneXunjianTimeData()
      },
      selectEveryOneXunjianTimeYear (val) {
        this.loadsearchEveryOneXunjianTimeParams = val
        this.loadSearchXunjianTimeParams()
        this.searchEveryOneXunjianTimeData()
      }
    }
  }
</script>
<style lang="less">
  .chart-title {
    font-size: 15px;
    width: 100%;
    text-align: center;
    line-height: 15px;
    font-weight: bolder;
    margin-bottom: 15px;
  }

  .col-margin-padding {
    /*padding: 10px;*/
    margin-bottom: 30px;
  }

  .chart-content {
    margin-top: 20px;
    padding: 10px;
  }
</style>