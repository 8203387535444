<template>
  <div>
    <a-button @click="clickInspectionTime">巡检次数统计</a-button>
    <a-button @click="clickDayInspectionList">当天巡检记录</a-button>
    <a-modal v-model="inspectionTimesModal" :centered="true" :footer="null" :width="1700">
      <a-card
        :headStyle="{paddingLeft:0}"
        :bordered="false"
        style="width:100%"
        :tab-list="tabListNoTitle"
        :active-tab-key="noTitleKey"
        @tabChange="key => onTabChange(key, 'noTitleKey')"
      >
        <div v-if="noTitleKey === 'xunjianTime'">
          <XunjianTimes :mapId="mapId"></XunjianTimes>
        </div>
        <div v-else-if="noTitleKey === 'xunjianKmAndTime'">
          <XunjianKmAndTime :mapId="mapId"></XunjianKmAndTime>
        </div>
        <div v-else>
          <XunjianKmAndTimeList :mapId="mapId"></XunjianKmAndTimeList>
        </div>
      </a-card>
    </a-modal>
    <a-modal :centered="true" v-model="dayInspectionListModal" :footer="null" :width="bodyWidth">
      <div class="details" style="margin-top: 0px;">
        <div class="details-title" style="margin-top: 0;">
          <div class="details-title-text">当天巡检记录</div>
          <i class="details-title-text-i"></i>
        </div>
      </div>
      <XunjianList></XunjianList>
    </a-modal>
  </div>
</template>
<script>
  import { mapState } from 'vuex'
  import XunjianTimes from './XunjianTimes'
  import XunjianList from '../../statisticsChart/XunjianList'
  import XunjianKmAndTime from '../../statistics/xunjian/XunjianKmAndTime'
  import XunjianKmAndTimeList from '../../statistics/xunjian/XunjianList'

  export default {
    name: 'buttonStatistics',
    mixins: [],
    components: { XunjianList, XunjianTimes, XunjianKmAndTime, XunjianKmAndTimeList },
    props: {
      mapId: {}
    },
    data () {
      return {
        noTitleKey: 'xunjianTime',
        tabListNoTitle: [
          {
            key: 'xunjianTime',
            tab: '巡检次数统计'
          },
          {
            key: 'xunjianKmAndTime',
            tab: '巡检公里时长统计'
          },
          {
            key: 'data',
            tab: '数据'
          }
        ],
        inspectionTimesModal: false,
        dayInspectionListModal: false
      }
    },
    computed: {
      ...mapState({
        bodyWidth: (state) => {
          const width = state.app.bodyWidth
          return width > 1400 ? 1400 : width
        }
      })
    },
    methods: {
      onTabChange (key, type) {
        this[type] = key
      },
      clickInspectionTime () {
        this.inspectionTimesModal = true
      },
      clickDayInspectionList () {
        this.dayInspectionListModal = true
      }
    }
  }
</script>
<style  scoped>
  button {
    margin-left: 10px;
    box-shadow: -1px 1px 3px rgba(0, 0, 0, 0.349019607843137);
    color: black;
  }
</style>

