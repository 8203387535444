<template>
  <div>
    <div @click="enlargementModal"></div>
    <a-tag color="#108ee9" @click="enlargementModal" style="margin-bottom: 10px">大屏预览</a-tag>
    <!--    <iframe :src="src + technologyId + '?token=' + token " style="width: 1000px;height: 600px;border: none;">-->
    <!--    </iframe>-->
  </div>
</template>
<script>
  import { DEFAULT_X_AUTH_TOKEN } from '../../../store/mutation-types'
  import VueCookie from 'vue-cookie'

  export default {
    name: 'DeviceFlowChart',
    props: {
      technologyId: {
        type: [String, Number]
      }
    },
    data () {
      return {
        src: 'https://qrcode.bitcooltech.com?technologyId=',
        token: VueCookie.get(DEFAULT_X_AUTH_TOKEN)
      }
    },
    methods: {
      enlargementModal () {
        window.open('/equipment2/technology/3d/' + this.technologyId, '_bank')
      }
    }
  }
</script>