<template>
  <div class="layer-container" :style="{ width: (showOther ? 200 : 205) + 'px' }">
    <div class="layer-label">
      <a-row type="flex" class="layer-row">
        <a-col :span="showOther ? 6 : 7">
          <menu-tree
            ref="menuTreeRef"
            class="p-l-12"
            :menuTreeData="layerTree"
            :selectedValue="defaultSelectedValue"
            @onChange="dropMenuChange"
            :dropdownContainerStyle="dropStyle"
          ></menu-tree>
        </a-col>
        <!--        <a-divider type="vertical" class="p-t-24 m-t-8"/>-->
        <!--        <a-col :span="showOther ? 4 : 6">-->
        <!--          <a class="a-label">-->
        <!--            <a-dropdown placement="bottomCenter">-->
        <!--              <div class="ant-dropdown-link" :trigger="['click']" style="width: 100%;">宁夏</div>-->
        <!--              <div slot="overlay" style="height: 400px; overflow: auto; background: white; margin-top: 2px;border-radius: 5px;">-->
        <!--                <a-tree v-if="areaTree.length" defaultExpandAll :treeData="areaTree" @select="selectArea"></a-tree>-->
        <!--              </div>-->
        <!--            </a-dropdown>-->
        <!--          </a>-->
        <!--        </a-col>-->
        <template v-if="showOther">
          <a-divider type="vertical" class="p-t-24 m-t-8"/>
          <a-col :span="6">
            <div class="a-label">
              <a-dropdown placement="bottomLeft">
                <div>
                  <a-badge
                    :count="totalElements"
                    :overflowCount="99"
                    :numberStyle="{ padding: '0 4px', fontSize: '5px' }"
                    :offset="[1, -3]"
                  >
                    <a-icon type="bell" style="font-size: 15px; cursor: pointer;"/>
                  </a-badge>
                </div>
                <a-menu slot="overlay" style="padding: 15px;width: 350px">
                  <template v-if="warningInfoList.length>1">
                    <a-menu-item v-for="item in warningInfoList" :key="item.id">
                      <div
                        @click="clickWarningInfo(item.id)"
                        :style="{ color: item.warningType === 'FAULT' ? '#f5222d' : '#1890ff' }"
                      >
                        <a-icon
                          :type="item.warningType === 'FAULT' ? 'warning' : 'info-circle'"
                          style="margin-right: 10px;"
                        />
                        {{ item.pointName}}#{{ item.title }}
                        <div style="color: #666666; margin-top: 5px; width: 100%;">
                          <a-row>
                            <a-col span="12"
                            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
                              moment(item.dateCreated).startOf('hour').fromNow()
                              }}
                            </a-col
                            >
                            <a-col span="12" style="text-align: right;">{{
                              moment(item.dateCreated).format('YYYY-MM-DD HH:MM:SS')
                              }}
                            </a-col>
                          </a-row>
                        </div>
                      </div>
                    </a-menu-item>
                    <a-menu-divider/>
                    <a-menu-item
                      key="index"
                      style="text-align: center; font-weight: bold;"
                      v-if="warningInfoList.length > 0"
                    >
                      <div @click="clickMoreWarningInfo()">查看更多</div>
                    </a-menu-item>
                  </template>
                  <template v-else>暂无预警信息</template>
                </a-menu>
              </a-dropdown>
            </div>
          </a-col>
          <!--          <a-divider type="vertical" class="p-t-24 m-t-8"/>-->
          <!--          <a-col :span="4">-->
          <!--            <a class="a-label">-->
          <!--              <span>管理员</span>-->
          <!--            </a>-->
          <!--          </a-col>-->
        </template>
        <a-divider type="vertical" class="p-t-24 m-t-8"/>
        <a-col :span="showOther ? 6 : 6">
          <a-dropdown :trigger="['hover']" placement="bottomCenter">
            <div class="drop-container">
              <div class="ant-modal-title drop-container-title">
                <template v-for="item in mapStyleItems">
                  <div class="cursor-pointer ant-dropdown-link" v-if="item.value === chooseMapStyle">
                    {{ item.label }}
                  </div>
                </template>
              </div>
            </div>
            <a-menu slot="overlay" style="width: 95px;">
              <template v-for="(item, index) in mapStyleItems">
                <a-menu-item :key="item.value">
                  <a @click="chooseMapStyleHandle(item)">{{ item.label }} &nbsp;&nbsp;<a-icon
                    type="check"
                    v-if="chooseMapStyle === item.value"
                    style="color: #1e88e5; margin-top: 4px;"
                    class="f-r"
                  />
                  </a>
                </a-menu-item>
                <a-menu-divider v-if="index !== mapStyleItems.length - 1"></a-menu-divider>
              </template>
            </a-menu>
          </a-dropdown>
        </a-col>
      </a-row>
    </div>
    <a-modal title="预警信息管理" :footer="null" :width="1450" v-model="warningModal">
      <WarningTable ref="warningTableRef"></WarningTable>
    </a-modal>
    <a-modal title="预警信息详情" :footer="null" :width="1000" v-model="warningDetailModal">
      <WarningDetail ref="warningDetailRef" @loactionWarnInfo="loactionWarnInfo"></WarningDetail>
    </a-modal>
  </div>
</template>
<script>
  import MenuTree from '../../../components/menuTree'
  import SERVICE_URLS from '../../../api/service.url'
  import moment from 'moment'
  import WarningTable from '../../equipment2/warning/Table'
  import WarningDetail from '../../equipment2/warning/Detail'

  export default {
    name: 'Coverage',
    components: { MenuTree, WarningTable, WarningDetail },
    props: {
      mapId: {
        type: String,
        default: undefined
      },
      // 显示预警等信息
      showOther: {
        type: Boolean,
        default: true
      }
    },
    data () {
      return {
        layerTree: [],
        dropStyle: {
          top: '36px',
          right: '36px'
        },
        areaTree: [],
        defaultSelectedValue: [],
        mapStyleItems: [
          {
            value: 'normal',
            label: '标准'
          },
          {
            value: 'dark',
            label: '幻影黑'
          },
          {
            value: 'light',
            label: '月光银'
          },
          {
            value: 'whitesmoke',
            label: '远山黛'
          },
          {
            value: 'fresh',
            label: '草色青'
          },
          {
            value: 'grey',
            label: '雅士灰'
          },
          {
            value: '8ea104d16c14b242522f70fbda71927c',
            label: '自定义'
          },
          {
            value: 'graffiti',
            label: '涂鸦'
          },
          {
            value: 'macaron',
            label: '马卡龙'
          },
          {
            value: 'darkblue',
            label: '靛青蓝'
          },
          {
            value: 'wine',
            label: '酱籽'
          },
          {
            value: 'satellite',
            label: '卫星图'
          }
        ],
        chooseMapStyle: this.$store.state.common.mapStyle,
        warningInfoList: [],
        warningParams: {
          facilityId: '',
          deviceId: '',
          type: '',
          mapId: this.mapId,
          warningDealStatus: 'UN_PROCESSED'
        },
        warningModal: false,
        warningDetailModal: false,
        totalElements: ''
      }
    },
    created () {
      this.loadAreaTree()
      this.getWarningInfo()
    },
    methods: {
      loactionWarnInfo (data) {
        this.$emit('loactionWarnInfo', data)
      },
      moment,
      loadLayerTree () {
        this.defaultSelectedValue = []
        this.$http(this, {
          url: SERVICE_URLS.equipment2.monitorPointApi.layerSelectType2,
          params: {
            mapInfoId: this.mapId
          },
          noTips: true,
          success: (data) => {
            this.$nextTick(() => {
              this.layerTree = data.body
              //  this.buildDefaultSelectedValue(data.body)
              this.leftDropChangeLayer('monitoringPoint')
            })
          },
          error: () => {
            console.error('Load monitor layerSelect data error.')
          }
        })
      },
      buildDefaultSelectedValue (value) {
        value.forEach((item) => {
          // if (item.value !== 'INSPECT_0' && item.value !== 'CAR_1') {
          this.defaultSelectedValue.push(item.value)
          // }
        })
        const event = 'selectedValue'
        this.$emit(event, this.defaultSelectedValue)
      },
      loadAreaTree () {
        this.$http(this, {
          url: SERVICE_URLS.equipment2.equipmentInfoApi.areaTree,
          noTips: true,
          success: (data) => {
            this.areaTree = data.body
          }
        })
      },
      dropMenuChange (selectedValue) {
        this.$emit('selectedValue', selectedValue)
      },
      selectArea (selectedKeys, e) {
        this.$emit('selectedCityName', e.node.title)
      },
      chooseMapStyleHandle (item) {
        this.chooseMapStyle = item.value
        this.$store.commit('SET_MAP_STYLE', item.value)
      },
      getWarningInfo () {
        if (this.showOther) {
          this.$http(this, {
            url: SERVICE_URLS.equipment2.warningApi.search_for_map,
            noTips: true,
            data: this.warningParams,
            success: (data) => {
              this.warningInfoList = data.body.content
              this.totalElements = data.body.totalElements
            }
          })
        }
      },
      clickMoreWarningInfo () {
        this.warningModal = true
        this.$nextTick(() => {
          this.$refs.warningTableRef.searchParams.mapId = this.mapId
          this.$refs.warningTableRef.search()
        })
      },
      clickWarningInfo (id) {
        this.warningDetailModal = true
        this.$nextTick(() => {
          this.$refs.warningDetailRef.span = 12
          this.$refs.warningDetailRef.loadData(id)
        })
      },
      leftDropChangeLayer (leftDropChecked) {
        this.defaultSelectedValue = []
        this.layerTree.forEach((item) => {
          //if (leftDropChecked === 'monitoringPoint') {
          if (item.value === 'project' || item.pId === 'project' || item.pId === null) {
            this.defaultSelectedValue.push(item.value)
          }
          // } else
          if (leftDropChecked === 'xunjian') {
            if (item.value === 'INSPECT_0') {
              this.defaultSelectedValue.push(item.value)
            }
          } else if (leftDropChecked === 'car') {
            if (item.value === 'CAR_1') {
              this.defaultSelectedValue.push(item.value)
            }
          }
        })
        const event = 'selectedValue'
        this.$emit(event, this.defaultSelectedValue)
      }
    }
  }
</script>
