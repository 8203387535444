<template>
  <div :id="id"></div>
</template>
<script>
  import G2 from '@antv/g2'

  export default {
    name: 'bar',
    props: {
      data: Object,
      // 同一个页面的id不能重复
      id: {
        type: String,
        request: true
      },
      count: {
        type: [Number, String]
      },
      xName: {
        type: String,
        default: 'item'
      },
      label: {
        type: Boolean,
        default: true
      },
      legend: {
        type: Boolean,
        default: false
      },
      pieColor: {
        type: Array
      },
      chartOnClick: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        color: []
      }
    },
    watch: {
      data (val) {
        this.data = val
        this.chart.source(this.data ? this.data.data : [])
        this.chart.guide().clear()
        this.chart.guide().html({
          position: ['50%', '50%'],
          html: '<div style="color:#8c8c8c;font-size: 14px;text-align: center;width: 10em;"><p>总数</p><span style="color:#8c8c8c;font-size:20px">' + `${this.data ? this.data.allCount : ''}` + '</span></div>',
          alignX: 'middle',
          alignY: 'middle'
        })
        this.chart.repaint()
      }

    },
    mounted () {
      this.initChart()
    },
    methods: {
      initChart () {
        // this.getpercent()
        const chart = new G2.Chart({
          container: this.id,
          forceFit: true,
          height: 300,
          padding: 'auto'
        })
        chart.source(this.data ? this.data.data : [], {
          percent: {
            formatter: val => {
              val = (parseFloat((val * 100).toPrecision(12))) + '%'
              return val
            }
          }
        })
        chart.coord('theta', {
          radius: 0.75,
          innerRadius: 0.6
        })
        chart.legend(`${this.xName}`, {
          position: 'left-center',
          itemWidth: 100
        })
        chart.tooltip({
          showTitle: false,
          itemTpl: '<li><span style="background-color:{color};" class="g2-tooltip-marker"></span>{name}: {value}</li>'
        })
        // 辅助文本
        chart.guide().html({
          position: ['50%', '50%'],
          html: '<div style="color:#8c8c8c;font-size: 14px;text-align: center;width: 10em;"><p>总数</p><span style="color:#8c8c8c;font-size:20px">' + `${this.data ? this.data.allCount : ''}` + '</span></div>',
          alignX: 'middle',
          alignY: 'middle'
        })
        const interval = chart.intervalStack()
          .position('percent')
          .color(`${this.xName}`)
          .tooltip(`${this.xName}` + '*count', (context, count) => {
            // percent = percent * 100 + '%'
            return {
              name: context,
              value: count + '次'
            }
          })
          .style({
            lineWidth: 1,
            stroke: '#fff'
          })
        if (this.label) {
          interval.label('percent', {
            formatter: (val, item) => {
              return item.point[`${this.xName}`] + ': ' + val
            }
          })
        }
        if (this.chartOnClick) {
          chart.on('interval:click', ev => {
            const data = ev.data
            if (data) {
              const item = data._origin
              this.$emit('clickPie', item)
            }
          })
        }
        this.chart = chart
        this.chart.render()
      }
    }
  }
</script>
<style>
</style>