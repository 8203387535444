<template>
  <right-form-container :title="currentItem.name + ' 车辆信息'" v-model="visible" @close="close">
    <div
      v-malihu-scrollbar
      class="mCustomScrollbar"
      style="overflow-y: auto;"
      :style="{ 'max-height': bodyHeight + 'px' }">
      <a-skeleton active :loading="ifSub">
        <div class="details" style="background-color: white; height: auto; margin-top: 0px;">
          <div class="p-16 p-b-4">
            <div class="details-title" style="margin-top: 0px;">
              <div class="details-title-text"> 基础信息</div>
              <i class="details-title-text-i"></i>
            </div>
            <a-row class="details-content-rowBasicInfo">
              <a-col :span="24"><span>车辆名称：</span> {{ formItem.carInfo.fullName }}</a-col>
              <a-col :span="12"><span>所属部门：</span>{{ formItem.carInfo.department.name || '-' }}</a-col>
              <a-col :span="12"><span>车牌号码：</span> {{ formItem.carInfo.platNumber }}</a-col>
              <a-col :span="12"><span>车辆速度：</span> {{ formItem.carInfo.speed }}km/h</a-col>
              <a-col :span="12"><span>车辆方向：</span> {{ formItem.carInfo.course }}</a-col>
              <a-col :span="12"><span>车辆载重：</span> {{ formItem.carInfo.volume }}吨</a-col>
              <a-col :span="12"><span>购车时间：</span> {{ formItem.carInfo.buyDate}}</a-col>
              <a-col :span="12"
              ><span>车辆状态：</span>
                <a-tag v-if="formItem.carInfo.status === 'FREE'">空闲</a-tag>
                <a-tag color="red" v-if="formItem.carInfo.status === 'OFFLINE'">离线</a-tag>
                <a-tag color="green" v-if="formItem.carInfo.status === 'GOING'">执行中</a-tag>
              </a-col>
              <a-col :span="12">
                <span>用途：</span>
                <a-tag v-if="formItem.carInfo.useType === 'OTHER'">其他</a-tag>
                <a-tag v-if="formItem.carInfo.useType === 'XJ'" color="#87d068">巡检</a-tag>
                <a-tag v-if="formItem.carInfo.useType === 'QY'" color="#108ee9">清运</a-tag>
              </a-col>
            </a-row>
            <div class="details-title" v-show="formItem.record.id">
              <div class="details-title-text">最新任务信息</div>
              <i class="details-title-text-i"></i>
            </div>
            <div class="details-title" v-show="formItem.loopRecord.id">
              <div class="details-title-text">最新清运信息</div>
              <i class="details-title-text-i"></i>
            </div>
            <a-row v-if="formItem.carInfo.useType === 'QY'" class="details-content-rowBasicInfo" v-show="formItem.loopRecord.id">
              <a-col :span="12"><span>执行司机：</span> {{ formItem.loopRecord.driver.name }}</a-col>
              <a-col :span="12"><span>清运耗时：</span> {{ formItem.loopRecord.durationStr }}</a-col>
              <a-col :span="12"><span>任务状态：</span>
                <a-tag v-if="formItem.loopRecord.status === 'NOT'">未执行</a-tag>
                <a-tag color="#108ee9" v-if="formItem.loopRecord.status === 'COMPLETED'">已完成</a-tag>
                <a-tag color="#87d068" v-if="formItem.loopRecord.status === 'DOING'">执行中</a-tag>
              </a-col>
              <a-col :span="24" v-if="formItem.loopRecord.status === 'DOING'||formItem.loopRecord.status === 'COMPLETED'">
                <span>清运时间：</span>{{ formItem.loopRecord.startTime }} ~ {{ formItem.loopRecord.endTime || '至今' }}
              </a-col>
              <!--              <a-col :span="24"><span>清运内容：</span>{{ formItem.loopRecord.summary }}</a-col>-->
              <a-col :span="24" v-if="formItem.loopRecord.status === 'COMPLETED'">
                <span>开始位置：</span>{{ formItem.recordTrail.startLocation }}
              </a-col>
              <a-col :span="24" v-if="formItem.loopRecord.status === 'COMPLETED'">
                <span>完成位置：</span>{{ formItem.recordTrail.endLocation }}
              </a-col>
              <a-col :span="24" v-if="formItem.loopRecord.status === 'COMPLETED'">
                <span>行驶公里：</span>{{ formItem.loopRecord.mileage }} km
              </a-col>
            </a-row>
            <a-row v-else class="details-content-rowBasicInfo" v-show="formItem.record.id">
              <a-col :span="12"><span>执行人：</span> {{ formItem.record.executeUser.name }}</a-col>
              <a-col :span="12"><span>制定人：</span> {{ formItem.record.managerUser.name }}</a-col>
              <a-col :span="12"><span>任务耗时：</span> {{ formItem.record.durationStr }}</a-col>
              <a-col :span="12"
              ><span>任务状态：</span>
                <a-tag v-if="formItem.record.status === 'NOT'">未执行</a-tag>
                <a-tag color="#108ee9" v-if="formItem.record.status === 'COMPLETED'">已完成</a-tag>
                <a-tag color="#87d068" v-if="formItem.record.status === 'DOING'">执行中</a-tag>
              </a-col>
              <a-col :span="24" v-if="formItem.record.type === 'GIVEN'"
              >
                <a-row style="padding: 0">
                  <a-col style="padding: 0;color: #4f4a4a" :span="6">&nbsp;&nbsp;&nbsp;&nbsp;计划时间：</a-col>
                  <a-col style="padding: 0" :span="18">{{ formItem.record.planStartTime }} ~ {{ formItem.record.planEndTime }}
                  </a-col>
                </a-row>
              </a-col>
              <a-col :span="24" v-if="formItem.record.status === 'COMPLETED'"
              >
                <a-row style="padding: 0">
                  <a-col style="padding: 0;color: #4f4a4a" :span="6">&nbsp;&nbsp;&nbsp;&nbsp;执行时间：</a-col>
                  <a-col style="padding: 0" :span="18">{{ formItem.record.startTime }} ~
                    {{ formItem.record.endTime || '至今' }}
                  </a-col>
                </a-row>
              </a-col>
              <a-col :span="24" v-if="formItem.record.status === 'DOING'"
              >
                <a-row style="padding: 0">
                  <a-col style="padding: 0;color: #4f4a4a" :span="6">&nbsp;&nbsp;&nbsp;&nbsp;执行时间：</a-col>
                  <a-col style="padding: 0" :span="18">{{ formItem.record.startTime }} ~
                    {{ formItem.record.endTime || '至今' }}
                  </a-col>
                </a-row>
              </a-col>
              <a-col :span="24"><span>执行内容：</span>{{ formItem.record.summary }}</a-col>
              <a-col :span="24" v-if="formItem.record.status === 'COMPLETED'"
              >
                <a-row style="padding: 0">
                  <a-col style="padding: 0;color: #4f4a4a" :span="6">&nbsp;&nbsp;&nbsp;&nbsp;开始位置：</a-col>
                  <a-col style="padding: 0" :span="18">{{ formItem.recordTrail.startLocation }}</a-col>
                </a-row>
              </a-col>
              <a-col :span="24" v-if="formItem.record.status === 'COMPLETED'"
              >
                <a-row style="padding: 0">
                  <a-col style="padding: 0;color: #4f4a4a" :span="6">&nbsp;&nbsp;&nbsp;&nbsp;完成位置：</a-col>
                  <a-col style="padding: 0" :span="18">{{ formItem.recordTrail.endLocation }}</a-col>
                </a-row>
              </a-col>
              <a-col :span="24" v-if="formItem.record.status === 'COMPLETED'"
              ><span>行驶公里：</span>{{ formItem.record.mileage }}
                km
              </a-col>
            </a-row>
          </div>
          <div :id="detailMapContainerId" v-show="formItem.record.id||formItem.loopRecord.id"
               style="height: 200px; margin-left: 12px; margin-right: 12px;"></div>
          <div class="p-16" style="padding-top: 4px;">
            <div class="details-title">
              <div class="details-title-text">设备信息</div>
              <i class="details-title-text-i"></i>
            </div>
            <a-row class="details-content-rowBasicInfo">
              <!--<a-col :span="12"><span>设备ID：</span> {{formItem.carInfo.objectId}}</a-col>-->
              <a-col :span="24"><span>设备号码：</span> {{ formItem.carInfo.macId }}</a-col>
              <!--<a-col :span="12"><span>设备型号：</span> {{formItem.carInfo.macName}}</a-col>-->
              <a-col :span="24"><span>SIM卡号：</span>{{ formItem.carInfo.sim || '-' }}</a-col>
              <a-col :span="12"><span>到期时间：</span> {{ formItem.carInfo.blockDate }}</a-col>
              <a-col :span="12"
              ><span>设备状态：</span>
                <a-tag color="#2db7f5" v-if="formItem.carInfo.offline === '1'">静止</a-tag>
                <a-tag color="green" v-if="formItem.carInfo.offline === '2'">行驶</a-tag>
                <a-tag color="red" v-if="formItem.carInfo.offline === '0'">离线</a-tag>
              </a-col>
            </a-row>
            <div class="details-title">
              <div class="details-title-text">车辆图片</div>
              <i class="details-title-text-i"></i>
            </div>
            <div class="details-content-rests">
              <template v-if="formItem.images.length">
                <qiniu-image-upload ref="qiniuImageRef" type="view" :items="formItem.images"/>
              </template>
              <template v-else>
                <div class="details-content-rests-noImageState">
                  <a-icon class="details-content-rests-noImageState-icon" type="smile"/>
                  <span>暂未上传图片</span>
                </div>
              </template>
            </div>
            <div class="details-title"></div>
          </div>
        </div>
      </a-skeleton>
    </div>
    <div style="padding: 16px;">
      <div class="hide">{{ mapStyle }}</div>
      <a-row :gutter="16">
        <a-col :span="8">
          <a-button type="primary" icon="environment" @click="showHistoryTrail()">历史轨迹</a-button>
        </a-col>
        <a-col :span="8">
          <a-button type="primary" icon="sync" @click="handleRecordDetail()">轨迹回放</a-button>
        </a-col>
        <a-col :span="8">
          <a-button v-if="formItem.carInfo.useType==='QY'" type="primary" icon="database" @click="handleLoopHistory()">历史清运</a-button>
          <a-button v-else type="primary" icon="database" @click="handleHistory()">历史任务</a-button>
        </a-col>
      </a-row>
    </div>
    <!--车辆历史轨迹-->
    <a-modal title="历史轨迹" :width="1520" centered :footer="null" :visible="historyTrailModal" @cancel="closeHistoryTrailModal">
      <history-track :carId="carId" ref="historyTrailRef" date="today"></history-track>
    </a-modal>
    <!--车辆任务历史Model-->
    <a-modal title="历史任务" :width="1520" centered :footer="null" :visible="historyModal" @cancel="closeHistoryModal">
      <history-list ref="historyRef"></history-list>
    </a-modal>
    <!--车辆清运历史Model-->
    <a-modal title="历史清运" :width="1520" centered :footer="null" :visible="loopHistoryModal" @cancel="closeLoopHistoryModal">
      <loop-history-list ref="loopHistoryRef"></loop-history-list>
    </a-modal>
    <!--车辆任务详情Model-->
    <a-modal
      title="记录详情"
      :width="1200"
      centered
      :footer="null"
      :visible="recordDetailModal"
      @cancel="closeRecordDetailModal">
      <record-detail ref="recordDetailRef"></record-detail>
    </a-modal>
    <!--清运任务详情Model-->
    <a-modal
      title="清运详情"
      :width="1200"
      centered
      :footer="null"
      :visible="loopRecordDetailModal"
      @cancel="closeLoopRecordDetailModal">
      <loop-record-detail ref="loopRecordDetailRef"></loop-record-detail>
    </a-modal>
  </right-form-container>
</template>

<script>
  import { lazyAMapApiLoaderInstance } from 'vue-amap'
  import UUID from '../../../utils/UUID'
  import SERVICE_URLS from '@/api/service.url'
  import { carInfoFormVO } from '../../car/info/common/carInfoFormVO'
  import QiniuImageUpload from '@/components/QiniuUpload/QiniuImageUpload'
  import HistoryList from '../../car/info/HistoryList'
  import LoopHistoryList from '../../car/looprecord/HistoryList'
  import LoopRecordDetail from '../../car/looprecord/Detail'
  import RecordDetail from '../../car/record/Detail'
  import containerClose from './mixins/container-close'
  import RightFormContainer from './RightFormContainer'
  import { mapState } from 'vuex'
  import mapStyle from './mixins/map-style'
  import HistoryTrack from '../../car/info/HistoryTrack'

  export default {
    name: 'MapCarInfoMapDetail',
    components: { HistoryTrack, QiniuImageUpload, HistoryList, LoopHistoryList, RecordDetail, LoopRecordDetail, RightFormContainer },
    mixins: [containerClose, mapStyle],
    data () {
      return {
        carId: null,
        ifSub: false,
        currentItem: {
          name: ''
        },
        formatStr: 'YYYY/MM/DD HH:mm:ss',
        formItem: carInfoFormVO(),
        labelCol: { span: 4 },
        wrapperCol: { span: 19 },
        /*弹框*/
        historyModal: false,
        historyTrailModal: false,
        loopHistoryModal: false,
        recordDetailModal: false,
        loopRecordDetailModal: false,
        /*地图*/
        detailMapContainerId: new UUID().id,
        detailMap: null,
        pathArray: null
      }
    },
    computed: {
      ...mapState({
        bodyHeight: (state) => {
          return state.app.bodyHeight - 200
        }
      })
    },
    methods: {
      loadData (id) {
        this.formItem = carInfoFormVO()
        this.carId = id
        this.initDate(id)
      },
      initDate (id) {
        if (id) {
          this.$http(this, {
            url: SERVICE_URLS.car.carInfoApi.viewAll,
            params: { id },
            noTips: true,
            success: (data) => {
              Object.assign(this.formItem, data.body)
              this.currentItem.name = this.formItem.carInfo.platNumber
              lazyAMapApiLoaderInstance.load().then(() => {
                this.detailMap = new AMap.Map(this.detailMapContainerId, {
                  resizeEnable: true,
                  mapStyle: 'amap://styles/normal',
                  center: [106.230909, 38.487193],
                  zoom: 11
                })
                this.buildMapOtherLayer()
                this.drawLine(this.formItem.record.id, this.formItem.recordTrail.position)
              })
            }
          })
        }
      },
      /*画线方法*/
      drawLine (id, path) {
        var polyline = new AMap.Polyline({
          path: eval(path),
          showDir: true,
          strokeColor: '#2d8cf0', //线颜色--蓝色
          //strokeOpacity: this.online ? 0 : 1,     //线透明度
          strokeOpacity: 1, //线透明度
          strokeWeight: 4, //线宽
          lineJoin: 'round', // 折线拐点连接处样式
          extData: {
            key: id
          }
        })
        this.detailMap.add(polyline)
        this.drowPoint(path)
      },
      drowPoint (path) {
        if (!path) {
          return
        }
        var gisArray = eval(path)
        var startPoint = eval(gisArray[0])
        var endPoint = eval(gisArray[gisArray.length - 1])
        let startIcon = new AMap.Icon({
          // 图标尺寸
          size: new AMap.Size(25, 34),
          // 图标的取图地址
          image: '//a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png',
          // 图标所用图片大小
          imageSize: new AMap.Size(135, 40),
          // 图标取图偏移量
          imageOffset: new AMap.Pixel(-9, -3)
        })
        let startMarker = new AMap.Marker({
          map: this.detailMap,
          icon: startIcon,
          offset: new AMap.Pixel(-11, -30),
          position: [startPoint[0], startPoint[1]]
        })
        let endIcon = new AMap.Icon({
          size: new AMap.Size(25, 34),
          image: '//a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png',
          imageSize: new AMap.Size(135, 40),
          imageOffset: new AMap.Pixel(-95, -3)
        })
        let endMarker = new AMap.Marker({
          map: this.detailMap,
          icon: endIcon,
          offset: new AMap.Pixel(-13, -33),
          position: [endPoint[0], endPoint[1]]
        })
        /*回放标记*/
        this.pathArray = path
        this.marker = new AMap.Marker({
          map: this.recordMap,
          position: [startPoint[0], startPoint[1]],
          icon: 'https://webapi.amap.com/images/car.png',
          offset: new AMap.Pixel(-26, -13),
          autoRotation: true,
          angle: -90
        })
        var passedPolyline = new AMap.Polyline({
          map: this.recordMap,
          strokeColor: '#AF5', //线颜色
          strokeWeight: 6, //线宽
          lineJoin: 'round' // 折线拐点连接处样式
        })
        this.marker.on('moving', (e) => {
          passedPolyline.setPath(e.passedPath)
        })
        /*添加到地图*/
        this.detailMap.add(startMarker)
        this.detailMap.add(endMarker)
        this.detailMap.setFitView()
        this.handleRunTrail()
      },
      handleRunTrail () {
        this.marker.moveAlong(eval(this.pathArray), 5000)
      },
      /*任务历史方法*/
      handleHistory () {
        this.historyModal = true
        this.$nextTick(() => {
          this.$refs['historyRef'].searchRecord(this.carId)
        })
      },
      handleLoopHistory () {
        this.loopHistoryModal = true
        this.$nextTick(() => {
          this.$refs['loopHistoryRef'].searchRecord(this.carId)
        })
      },
      closeHistoryModal () {
        this.historyModal = false
      },
      closeLoopHistoryModal () {
        this.loopHistoryModal = false
      },
      /*任务记录方法*/
      handleRecordDetail () {
        if (this.formItem.record.id) {
          this.recordDetailModal = true
          this.$nextTick(() => {
            this.$refs['recordDetailRef'].loadData(this.formItem.record.id)
          })
        } else if (this.formItem.loopRecord.id) {
          this.loopRecordDetailModal = true
          this.$nextTick(() => {
            this.$refs['loopRecordDetailRef'].loadData(this.formItem.loopRecord.id)
          })
        } else {
          this.$message.warning('暂无车辆任务信息！')
        }
      },
      closeRecordDetailModal () {
        this.recordDetailModal = false
      },
      closeLoopRecordDetailModal () {
        this.loopRecordDetailModal = false
      },
      /*历史轨迹*/
      showHistoryTrail () {
        this.historyTrailModal = true
      },
      closeHistoryTrailModal () {
        this.historyTrailModal = false
      }
    }
  }
</script>
