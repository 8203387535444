<template>
  <div class="map-composite" style="width: 100%; height: 100%;">
    <div :id="mapContainerId" class="map-container"></div>
    <div class="form-search-container">
      <div class="form-container-header">
        <a-input-search
          class="ant-modal-search"
          v-model="q"
          enterButton
          size="large"
          :loading="searchStatus"
          placeholder="请输入搜索关键字"
          @search="onSearch"
        >
          <a-select
            slot="addonBefore"
            v-if="this.leftDropData.length > 1"
            @change="leftDropChange"
            v-model="leftDropChecked"
            style="width: 70px; font-size: 16px;"
          >
            <a-select-option :key="item.value" :value="item.value" v-for="item in leftDropData"
            >{{ item.label }}
            </a-select-option>
          </a-select>
        </a-input-search>
      </div>

      <div class="form-container-body" v-if="leftFormShow">
        <search-xunjian-list
          ref="xunjianRef"
          v-if="leftDropChecked === 'xunjian'"
          :mapId="mapId"
          :searchKey="q"
          @onItemClick="formItemClick"
          @onSearchStart="searchStartHandle"
          @onSearchSuccess="searchSuccessHandle"
          @onSearchError="searchErrorHandle"
        ></search-xunjian-list>
        <search-car-list
          ref="carRef"
          v-if="leftDropChecked === 'car'"
          :mapId="mapId"
          :searchKey="q"
          @onItemClick="formItemClick"
          @onSearchStart="searchStartHandle"
          @onSearchSuccess="searchSuccessHandle"
          @onSearchError="searchErrorHandle"
        ></search-car-list>
        <search-device-list
          ref="deviceRef"
          v-if="leftDropChecked === 'device'"
          :mapId="mapId"
          :searchKey="q"
          @onSearchStart="searchStartHandle"
          @onSearchSuccess="searchSuccessHandle"
          @onSearchError="searchErrorHandle"
          @onItemClick="formItemClick"
        ></search-device-list>
        <search-monitoring-point
          ref="monitoringPointRef"
          v-if="leftDropChecked === 'monitoringPoint'"
          :mapId="mapId"
          :searchKey="q"
          @onSearchStart="searchStartHandle"
          @onSearchSuccess="searchSuccessHandle"
          @onSearchError="searchErrorHandle"
          @onItemClick="formItemClick">
        </search-monitoring-point>
      </div>
    </div>
    <coverage
      @selectedValue="layerOnChange"
      @selectedCityName="cityNameChange"
      @loactionWarnInfo="loactionWarnInfo"
      :showOther="leftDropData.length > 1 || leftDropChecked === 'monitoringPoint'"
      :mapId="mapId"
      ref="coverageRef"
    ></coverage>
    <xunjian-detail
      ref="xunjianDetailRef"
      v-if="rightFormChecked === 'xunjian'"
      v-model="rightFormShow"
      :dbDevicesData="dbDevicesData"
      @onPeopleFit="xunjianPeopleFitHandle"
      @close="closeRightForm"
    ></xunjian-detail>
    <car-detail
      ref="carDetailRef"
      v-if="rightFormChecked === 'car'"
      v-model="rightFormShow"
      @carItemLocation="carItemLocation"
      @close="closeRightForm"
    ></car-detail>
    <device-detail
      ref="deviceDetailRef"
      v-if="rightFormChecked === 'device'"
      v-model="rightFormShow"
      @close="closeRightForm"
      :mapId="mapId"
    ></device-detail>
    <monitoring-point-detail
      ref="monitoringPointDetailRef"
      v-if="rightFormChecked === 'monitoringPoint'"
      v-model="rightFormShow"
      @close="closeRightForm"
      :mapId="mapId">
    </monitoring-point-detail>
    <div class="hide">{{ intervalMessage }}</div>
    <div class="hide">{{ mapStyle }}</div>
    <CarbottomOperation class="bottom" v-if="leftDropChecked === 'car'" :mapId="mapId"></CarbottomOperation>
    <XunjianBottmOperation class="bottom" v-if="leftDropChecked === 'xunjian'" :mapId="mapId"></XunjianBottmOperation>
    <DeviceBottomOperation class="bottom" v-if="leftDropChecked === 'monitoringPoint'"></DeviceBottomOperation>
    <top :leftDropChecked="leftDropChecked" :countCarsData="countCarsData" :countXunjiansData="countXunjiansData" :countDeviceData="countDeviceData"
         style="position: absolute; top: 24px; left: 30%;"></top>
    <a-popover placement="leftBottom" class="bottom right" trigger="click"
               :visible="visible"
               @visibleChange="handleHoverChange">
      <template slot="content">
        <a-row style="width: 120px">
          <a-col :span="24"><img src="http://srcp.fangshuoit.com/o_1ec254c721l1n52rt4rdd9s879.png"><span class="title">集污池</span></a-col>
          <a-col :span="24"><img src="http://srcp.fangshuoit.com/o_1ec257ofroi51rk916c71s351t7k1h.png"><span class="title">小型泵站</span></a-col>
          <a-col :span="24"><img src="http://srcp.fangshuoit.com/o_1ec258ru2rtq1q69fl51oki112i1t.png"><span class="title">污水处理站</span></a-col>
          <a-col :span="24"><img src="http://srcp.fangshuoit.com/o_1ea1jke2f14ci1fgia701hat1p803j.png"><span class="title">小型泵站</span></a-col>
        </a-row>
      </template>
      <a-button style="color: black;font-weight: bold">图例</a-button>
    </a-popover>
  </div>
</template>
<script>
  import common from './mixins/common'
  import cars from './mixins/car'
  import xunjian from './mixins/xunjian'

  import mapInit from '../mixins/map-init'
  import SERVICE_URLS from '@/api/service.url'
  import monitoringPoint from './mixins/monitoring-point'


  import SearchXunjianList from './SearchXunjianList'
  import SearchCarList from './SearchCarList'
  import Coverage from './Coverage'
  import SearchDeviceList from './SearchDeviceList'
  import XunjianDetail from './XunjianDetail'
  import CarDetail from './CarDetail'
  import DeviceDetail from './DeviceDetail'

  import Top from './statisticsChart/Top'
  import CarbottomOperation from './statistics/car/Car'
  import XunjianBottmOperation from './statistics/xunjian/Xunjian'
  import SearchMonitoringPoint from './SearchMonitoringPoint'
  import MonitoringPointDetail from './MonitoringPointDetail'
  import overlayGroupConfig from './mixins/overlay-config'
  import DeviceBottomOperation from './statistics/device/Device'

  export default {
    props: {
      mapId: {}
    },
    mixins: [mapInit, cars, xunjian, common, monitoringPoint, overlayGroupConfig],
    components: {
      SearchXunjianList,
      SearchCarList,
      SearchDeviceList,
      XunjianDetail,
      Coverage,
      CarDetail,
      DeviceDetail,
      Top,
      SearchMonitoringPoint,
      MonitoringPointDetail,
      CarbottomOperation,
      XunjianBottmOperation,
      DeviceBottomOperation
    },
    watch: {
      mapId (val) {
        this.$nextTick(() => {
          this.restMap()
          this.init()
        })
      }
    },
    data () {
      return {
        topShow: false,
        searchParams: {
          mapId: this.mapId
        },
        countCarsData: {},
        countXunjiansData: {},
        countDeviceData: {},
        isComposite: true,
        visible: true
      }
    },
    created () {
      this.getStatisticsInfo()
      const self = this
      this.taskId = setInterval(() => {
        //更新地图上的数据
        self.checkMonitoringPointList()
        //更新预警铃铛的数据
        self.$refs.coverageRef.getWarningInfo()
      }, 30000)
    },
    destroyed () {
      if (this.map) {
        this.map.destroy()
        this.map = null
      }
      if (this.taskId !== undefined) {
        clearInterval(this.taskId)
      }
      if (this.removeOverMarkTips) {
        this.removeOverMarkTips()
      }
    },
    methods: {
      handleHoverChange (visible) {
        this.visible = visible
      },
      getStatisticsInfo () {
        this.$http(this, {
          url: SERVICE_URLS.car.carInfoApi.count_cars,
          noTips: true,
          data: this.searchParams,
          success: (data) => {
            this.countCarsData = data.body
          }
        })
        this.$http(this, {
          url: SERVICE_URLS.xj.xjRecord.statistic_general,
          noTips: true,
          data: this.searchParams,
          success: (data) => {
            this.countXunjiansData = data.body
          }
        })
        this.$http(this, {
          url: SERVICE_URLS.equipment2.equipmentInfoApi.statistics,
          data: this.searchParams,
          noTips: true,
          success: (data) => {
            this.countDeviceData = data.body
          }
        })
      }
    }
  }
</script>
<style scoped>
  .bottom {
    bottom: 40px;
    position: fixed;
    z-index: 10;
    margin-left: 40px;
  }

  .right {
    right: 0;
    bottom: 10px;
    height: 40px;
    width: 48px;
    padding: 0;
  }


  .title {
    color: black;
    font-weight: bold;
    margin-left: 10px;
  }

  img {
    width: 20px;
    height: 20px;
  }
</style>
