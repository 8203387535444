import coverView from './../../mixins/cover-view'
import SERVICE_URLS from '@/api/service.url'

const devices = {
  mixins: [coverView],
  data () {
    return {
      // 设备，设施，仓库，基础点线面组
      overlayGroups: [],
      searchCoverParams: {
        mapInfoId: this.mapId,
        typeLabels: []
      },
      currentMarker: null,
      dbDevicesData: []
    }
  },
  mounted () {
  },
  methods: {
    /**
     * 加载覆盖物
     */
    loadCoverMonitoringPointList () {
      this.$http(this, {
        url: SERVICE_URLS.equipment2.monitorPointApi.searchByLayer,
        noTips: true,
        data: this.searchCoverParams,
        success: (data) => {
        //  this.buildMonitoringPointCoverList(data)
        },
        error: () => {
          console.error('Render cover list error.')
        }
      })
    },
    buildMonitoringPointCoverList (data, isComposite) {
        try {
            this.dbDevicesData = data.body
            this.buildOverlayGroups(data.body, isComposite)
            this.setOverlayGroupsToMap(this.overlayGroups, this.map)
        } catch (e) {
          console.log(e)
          console.log('初始化监控点错误')
       }
      },
    /**
     * 构建覆盖物群组
     * 设备，设施，仓库，基础覆盖物群组
     * KEY规则{DEVICE_设备类型,FACILITY_设施类型,DEPOT_仓库类型,BASE}
     * @param dbOverlayData
     */
    buildOverlayGroups (dbOverlayData, isComposite) {
      dbOverlayData.forEach((item) => {
        let cover = this.buildCover(item, isComposite)
        let overlay = cover.cover[0]
        if (!item.systemModule) {
          // 基础点线面
          if (cover.type === 'text') {
            // 设置 labelLayer 到当前地图中
            this.getLabelLayer(this.map).add(overlay)
          } else {
            this.addOverlayToGroup('BASE', overlay)
          }
        } else {
            this.addOverlayToGroup('MONITORINGPOINT_' + item.instanceType, overlay)
        }
      })
    },
    addOverlayToGroup (key, overlay) {
      let group = this.findOverlayGroupByKey(key)
      if (group) {
        group.overlayGroup.addOverlay(overlay)
      } else {
        group = {
          key: key,
          overlayGroup: new AMap.OverlayGroup([overlay])
        }
        this.overlayGroups.push(group)
      }
    },
    removeSelectedOverLayStyle () {
      if (this.currentMarker) {
        this.removeSelectMarkerAnimation(this.currentMarker)
        this.currentMarker = null
      }
      if (this.selectedOverLayer) {
        this.selectedOverLayer.setOptions(this.selectedOldStyle)
        this.selectedOverLayer = null
        this.selectedOldStyle = null
      }
    },
    setSelectedOverLayStyle (overlayer) {
      if (overlayer.CLASS_NAME === 'AMap.Marker') {
        this.currentMarker = overlayer
       /* setTimeout(() => {
          this.setSelectMarkerAnimation(overlayer)
        }, 600)*/
        this.setTimeOutSelectedOerLayer(overlayer)
      } else {
        this.selectedOverLayer = overlayer
        this.selectedOldStyle = overlayer.getOptions()
        overlayer.setOptions({ borderWeight: 2, strokeColor: 'red' })
      }
    },
    /**
     * 覆盖物点击
     * @param target
     */
    coverClick (target) {
      if (this.leftDropData.length > 1 || (this.leftDropData.length === 1 && this.leftDropData[0].value === 'monitoringPoint')) {
        this.removeSelectedOverLayStyle()
        const extData = target.getExtData()
        const item = {
          id: extData.id,
          label: undefined
        }
       if (extData.module === 'monitoring_point') {
          item.label = 'monitoring_point'
        } else {
          return
        }
        this.setSelectedOverLayStyle(target)
        if (this.currentMarker) {
          this.map.setCenter(this.currentMarker.getPosition())
        } else {
          if (!this.map) return
          this.map.setFitView(target)
        }
        this.rightFormChecked = 'monitoringPoint'
        this.rightFormShow = true
        this.$nextTick(() => {
          this.getRightDetailRef().loadData(item)
        })
      }
    },
    /**
     * 显示或者隐藏覆盖物群组
     * @param selectedValue
     */
    showOrHideDeviceOverlayGroup (selectedValue) {
      const showOverlayGroupKeys = this.getShowOverlayGroupKeys(selectedValue)
      const hideOverlayGroupKeys = this.getHideOverlayGroupKeys(selectedValue)
      this.showOverlayGroupByKeys(showOverlayGroupKeys)
      this.hideOverlayGroupByKeys(hideOverlayGroupKeys)
    },
    getShowOverlayGroupKeys (selectedValue) {
      return selectedValue
    },
    getHideOverlayGroupKeys (selectedValue) {
      const hideOverlayGroupKeys = []
      this.currentLayerSelected.forEach((currentValue) => {
        const target = selectedValue.find((selected) => selected === currentValue)
        if (!target) {
          hideOverlayGroupKeys.push(currentValue)
        }
      })
      return hideOverlayGroupKeys
    },
    findOverlayGroupByKey (key) {
      return this.overlayGroups.find((group) => group.key === key)
    },
    showOverlayGroupByKeys (keys) {
      if (keys) {
        keys.forEach((key) => {
          this.showOverlayGroupByKey(key)
        })
      }
    },
    hideOverlayGroupByKeys (keys) {
      if (keys) {
        keys.forEach((key) => {
          this.hideOverlayGroupByKey(key)
        })
      }
    },
    showOverlayGroupByKey (key) {
      const group = this.findOverlayGroupByKey(key)
      if (group) {
        group.overlayGroup.show()
        if (group.isInCluster && this.markerCluster) {
          this.addMarkersToCluster(group.overlayGroup.getOverlays(), this.map)
        }
      }
    },
    hideOverlayGroupByKey (key) {
      const group = this.findOverlayGroupByKey(key)
      if (group) {
        if (group.isInCluster && this.markerCluster) {
          this.markerCluster.removeMarkers(group.overlayGroup.getOverlays())
        }
        group.overlayGroup.hide()
      }
    },
    monitoringPointItemClickHandle (type, item) {
      this.rightFormChecked = type
      this.rightFormShow = true
      this.$nextTick(() => {
        this.getRightDetailRef().loadData(item)
      })
      if (!item.hasMapOverlay) {
        return
      }
      this.removeSelectedOverLayStyle()
      if (item.mapOverlay === undefined) return;
      let overlay = this.findOverlayByKey(item.mapOverlay.id)
      if (overlay === undefined) {
        return
      }
      if (item.mapOverlay.type === 'polyline' || item.mapOverlay.type === 'polygon') {
        overlay.show()
        this.map.setFitView(overlay)
      } else {
        let position
        this.currentMarker = overlay
        position = overlay.getPosition()
        overlay.show()
        this.map.setCenter(position)
        this.map.setZoom(16)
/*        this.removeSelectMarkerAnimation(overlay)
        setTimeout(() => {
          this.setSelectMarkerAnimation(overlay)
        }, 600)*/
      }
      this.setSelectedOverLayStyle(overlay)

    },
    findOverlayByKey (key) {
      let target
      this.overlayGroups.forEach((group) => {
        group.overlayGroup.eachOverlay((overlay) => {
          if (overlay.getExtData()['key'] === key) {
            target = overlay
          }
        })
      })
      return target
    },
    /**
     * 清空页面所有覆盖物
     */
    clearDeviceOverlays () {
      this.getLabelLayer(this.map).clear()
      this.overlayGroups.forEach((x) => {
        x.overlayGroup.clearOverlays()
      })
      this.overlayGroups = []
    },
    /**
     * dyh
     * 综合地图 定时更新  图标的光圈
     */
    checkMonitoringPointList () {
      this.$http(this, {
        noLoadingBar: true,
        url: SERVICE_URLS.equipment2.monitorPointApi.searchByLayer,
        noTips: true,
        data: { mapInfoId: this.mapId },
        success: (data) => {
          this.checkUpdateMonitoringPointOverLay(data)
        },
        error: () => {
          console.error('  checkMonitoringPoint  error.')
        }
      })
    },
    /**
     * dyh
     * 通过后台请求数据 与 现有数据进行对比 更新图标状态
     * @param data
     */
    checkUpdateMonitoringPointOverLay (data) {
      var pointDbList = data.body;
      let newMarkersData = [];
      pointDbList.forEach((mp) => {
        this.dbDevicesData.forEach((dbMpp) => {
          if (mp.instanceId === dbMpp.instanceId && (mp.fault !== dbMpp.fault || mp.warn !== dbMpp.warn)) {
            let mpGroup = this.findOverlayGroupByKey('MONITORINGPOINT_' + mp.instanceType)
            let overLay = this.findOverlayByKey(mp.id)
            if (mpGroup && overLay) {
              newMarkersData.push(mp)
              mpGroup.overlayGroup.removeOverlay(overLay)
              this.removeMarkTips('marker-body-tips-' + this.mapContainerId + '-' + mp.id)
            }
          }
        })
      })
      if (newMarkersData.length > 0) {
        this.dbDevicesData = data.body
        this.buildOverlayGroups(newMarkersData, true)
        this.setOverlayGroupsToMap(this.overlayGroups, this.map)
      }
    },
    monitoringPointMapZoomChange () {
      var curentZoom = this.map.getZoom()
      this.overlayGroups.forEach((item) => {
        if (this.findOverlayGroupByKey) {
            var  overLayConfig = this.findOverlayGroupConfig(item.key)
            if (overLayConfig && !overLayConfig.isCluster) {
                if (overLayConfig.minZoom <= curentZoom && overLayConfig.maxZoom >= curentZoom) {
                  item.overlayGroup.show()
                } else {
                  item.overlayGroup.hide()
                }
            }
        }

      })
    }
  }
}

export default devices
