<template>
  <a-spin :spinning="spinning">
    <div class="details">
      <div class="details-title">
        <div class="details-title-text">基础信息</div>
        <i class="details-title-text-i"></i>
      </div>
      <div v-if="formItem.gatewayInfo">
        <template v-if="formItem.gatewayInfo.gatewayInfos.length > 0">
          <a-row
            class="details-content-rowBasicInfo"
            v-for="(item, index) in formItem.gatewayInfo.gatewayInfos"
            :key="index"
            style="height: 50px; overflow: auto;"
          >
            <a-col :span="8"><span>中文名称：</span>{{ item.cnName }}</a-col>
            <a-col :span="8"><span>英文名称：</span>{{ item.enName }}</a-col>
            <a-col :span="8"><span>网关编号：</span>{{ item.deviceGatewayNo }}</a-col>
          </a-row>
        </template>
        <div class="details-content-rests" v-else>
          <div class="details-content-rests-noImageState" style="height: 50px;">
            <a-icon class="details-content-rests-noImageState-icon" type="smile" />
            <span>暂无网关信息</span>
          </div>
        </div>
      </div>
    </div>
  </a-spin>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      spinning: false,
      formItem: {},
    }
  },
  methods: {},
}
</script>
